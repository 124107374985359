import React from 'react';
import { getLaufzeitOptions } from './Ensfeatures';
import { HookedRadioSwitchRow } from './HookedInputs';

export const Vertragslaufzeit = ({ offer }) => (
  <HookedRadioSwitchRow
    helpText={'Eine längere Vertragslaufzeit führt oft zu einem günstigeren Preis'}
    values={getLaufzeitOptions(offer)}
    field="contractDurationYears"
    title="Vertragslaufzeit"
  />
);
