import { navigateTo } from '../progress';
import { getUrl } from '../data';
import { addCustomFilter, addUserDataPostFields } from './sharedPostField';
import { AppStateGetterType } from '@/store';

export const priceRequestSubmit = () => {
  return async (dispatch: AppDispatch, getState: AppStateGetterType) => {
    dispatch({ type: 'SUBMIT_LOADING' });

    const state: RootState = getState();
    const request = JSON.parse(JSON.stringify(state.userData));
    const checkout = state.checkout;
    const formData = new FormData();

    addUserDataPostFields(state, request, formData);
    addCustomFilter(state, formData);

    formData.append('ensName', checkout.name);

    const result = await fetch(getUrl('/wp-json/ens/v1/ensOffer/submitPriceRequest'), {
      body: formData,
      method: 'post'
    })
      .then((o) => o.json())
      .catch(() => {
        return {
          success: false,
          message: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
        };
      });

    
    dispatch({
      type: 'SUBMIT_COMPLETED',
      success: result.success,
      text: result.message,
      notifications: null,
      voucherInfoText: null,
      orderGroup: null
    });
    dispatch(navigateTo('order_success'));
    
  };
};
