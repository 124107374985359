/* eslint-disable import/no-named-as-default-member */
import Fbtrack from 'react-facebook-pixel';

import { getQueryStrings } from '../shared/QueryParams';
import { TrackerType } from '@/actions/tracking/TrackerType';
import { EnsFieldsReducer } from '@/reducer/ensfields';
import { getPresetId } from '@/actions/shared/getPresetId';

const fbTracker: TrackerType = (event) => {
  let eventName = '';

  switch (event.name) {
    case 'initApp':
      eventName = 'ViewContent';
      break;
    case 'ensuranceListSelection':
      eventName = 'CustomizeProduct';
      break;
    case 'onPersonalInput':
      eventName = 'initiateCheckout';
      break;
    case 'insuranceBooked':
      eventName = 'Purchase';
      break;

    case 'offerMailProcessSubmit':
      // 8.8.2023 Auf Anfrage von Thomas deaktiviert
      //case 'angebotAnforderungSubmit':
      eventName = 'Lead';
  }

  if (!eventName) return;

  // Purchase event is only for the following presetsId
  // https://app.asana.com/0/1174272655772669/1204969351492285/f
  if (eventName === 'Purchase') {
    const presetId = getPresetId();

    if (
      ![
        '6467816fa9f681.54570767',
        '63f3589c03a763.24735554',
        '643584313b31f4.09286589',
        '643585e60e57a2.90170674',
        '633c220579c244.48461925'
      ].includes(presetId)
    )
      return;
  }

  if (getQueryStrings()?.consoleTrack) {
    console.log('Track FB event', eventName);
  }
  Fbtrack.track(eventName, event.data || {});
};

fbTracker.onInit = (endFields: EnsFieldsReducer) => {
  if (!endFields.ens_fb_tracking_id) return;
  Fbtrack.init(endFields.ens_fb_tracking_id, undefined, {
    autoConfig: true,
    debug: false
  });
  Fbtrack.pageView();
};

export default fbTracker;
