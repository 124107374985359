import React, { useCallback, useMemo } from 'react';

import { Button } from '@unserkunde/enscompare-components';
import { Box, Container, Text, createStyles, Title } from '@mantine/core';

import { selectDevMode } from '@/actions/devmode';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { EnsIcon } from 'forms/shared/AvailalbeEnsIcons';

const useStyles = createStyles((theme) => ({
  selectItemBox: {
    padding: theme.spacing.xs,
  },
  selectItemButton: {
    height: 'auto',
  },
  selectItemButtonInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing.sm,
  },
  container: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing.xs,
    color: theme.colors[theme.primaryColor][theme.primaryShade as number],
  },
  page: {
    paddingBottom: theme.spacing.xl,
  },
  wrapSpace: {
    whiteSpace: 'normal',
  },
  devicemodeIcon: {
    fontSize: '2.5rem',
  },
}));

const SelectItem = (props) => {
  const { devkey } = props;
  const dispatch = useAppDispatch();
  const onSelectDevice = useCallback(() => dispatch(selectDevMode(devkey)), [dispatch, devkey]);

  const { classes } = useStyles();

  return (
    <Box className={classes.selectItemBox}>
      <Button
        onClick={onSelectDevice}
        variant='outline'
        fullWidth
        className={classes.selectItemButton}>
        <div className={classes.selectItemButtonInner}>
          <Text fz='lg'>
            {!props.icon ? null : (
              <>
                <EnsIcon
                  className={classes.devicemodeIcon}
                  icon={props.icon}
                  useAppColor
                />
                &nbsp;&nbsp;
              </>
            )}
            {props.name}
          </Text>
          {!props.infotext ? null : (
            <Text
              className={classes.wrapSpace}
              ta='center'
              fz='xs'
              c='dimmed'>
              ({props.infotext})
            </Text>
          )}
        </div>
      </Button>
    </Box>
  );
};

const DeviceSelect = () => {
  const disabledDeviceKeysFromPreset = useAppSelector((state) => state.options.presetIdData?.excludedDeviceSelections);

  const disabledDeviceKeys = useMemo(
    () => [...(disabledDeviceKeysFromPreset?.split(',') || []), ...(window.ensOptions?.disabledDeviceKeys || [])],
    [disabledDeviceKeysFromPreset, window.ensOptions?.disabledDeviceKeys]
  );
  const devices = useAppSelector((state) => state.ensfields.devicemodes);

  const { classes } = useStyles();

  return (
    <Container className={classes.page}>
      <Title
        order={2}
        align='center'
        className={classes.title}>
        Was möchtest du versichern?
      </Title>
      <div className={classes.container}>
        {devices
          .filter((device) => device.selectable)
          .filter((device) => !disabledDeviceKeys.includes(device.devkey))
          .map((device) => (
            <SelectItem
              {...device}
              key={device.devkey}
            />
          ))}
      </div>
    </Container>
  );
};

export default DeviceSelect;
