import { CompareLinkResult } from '@/forms/ensuranceList/popups/CompareLinkResult';
import { LoadingEnsPageOverlaySettings } from '@/forms/loadingEnsPage/LoadingEnsPage';
import { GpsTrackerDetailsOverlay, PremiumserviceDetailsOverlay } from '@/forms/shared/pricing/CustomProductOverlays';
import { AnyAction } from 'redux';

export type OverlayControlVerboseSettings = {
  component: React.ElementType;
  settings?: {
    hideNavigation?: boolean;
  };
};

export type OverlayControlSettings = React.ElementType | OverlayControlVerboseSettings;

export const OverlayControls = {
  asd: () => {
    return 'asd Control';
  },
  gpsTracker: GpsTrackerDetailsOverlay,
  premiumservice: PremiumserviceDetailsOverlay,
  submitAngebotLink: CompareLinkResult,
  loadingEnsPage: LoadingEnsPageOverlaySettings,
} satisfies Record<string, OverlayControlSettings>;

export type OverlayNames = keyof typeof OverlayControls;

const defaultState = {
  overlayName: null as null | OverlayNames,
};

export const openOverlay = (overlayName: OverlayNames) => {
  return {
    type: 'OPEN_OVERLAY',
    payload: {
      overlayName,
    },
  };
};

export const closeOverlay = () => {
  return {
    type: 'CLOSE_OVERLAY',
  };
};

export const overlayReducer = (state = defaultState, action: AnyAction): typeof defaultState => {
  if (action.type === 'OPEN_OVERLAY') {
    return { ...state, overlayName: action.payload.overlayName };
  } else if (action.type === 'CLOSE_OVERLAY') {
    return { ...state, overlayName: null };
  }

  return state;
};
