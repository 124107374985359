import { ScrollIntoView } from '@/forms/shared/ScrollInfoView';
import { useAppSelector } from '@/hooks';
import { Box, Container, Space, Title, Loader, Center } from '@mantine/core';
import React from 'react';

import numeral from 'numeral';
import { GetIsDevMode } from '@/forms/shared/IsDevMode';
import { DebugDisplayCustomProducts } from './DebugDisplayCustomProducts';

import { SkipCustomProductsButton } from './SkipCustomProductsButton';
import { useCustomProductsInitialLoaded } from './useCustomProductsInitialLoaded';
import { TotalRow } from './CustomProductsTotalRow';
import { CustomProductsSelection } from './CustomProductRow';

export const CustomProducts = () => {
  const voucherAmount = useAppSelector((state) => state.checkout.offer?.voucherTotalEuroCent);
  const voucherInfo = useAppSelector((state) => state.voucher.voucher);

  const loaded = useCustomProductsInitialLoaded();

  if (!loaded)
    return (
      <Center>
        <Loader />
      </Center>
    );

  return (
    <>
      <ScrollIntoView />
      <>
        <Container>
          <SkipCustomProductsButton />
          {voucherInfo?.valid && (
            <>
              <Title order={4}>
                Wir schenken dir{' '}
                <Box
                  component='span'
                  c='primary'>
                  {numeral(voucherAmount).format('0.00')} €
                </Box>
              </Title>
              <Title order={6}>Diese können für folgende Zusatzprodukte verwendet werden:</Title>
            </>
          )}
          <CustomProductsSelection />
          <TotalRow />
          <Space h={'lg'} />
          {GetIsDevMode() && <DebugDisplayCustomProducts />}

          <SkipCustomProductsButton />
        </Container>
      </>
    </>
  );
};
