import { trackByName } from '../tracking';
import { focusTopSelect } from './focusTopSelect';

export type EnsPage =
  | 'order_success'
  | 'offerRequestProcessSettings'
  | 'inpsectionVoucher'
  | 'angebotfertig'
  | 'zusammenfassung'
  | 'bikeinfo'
  | 'paymentInfo'
  | 'personalInfo'
  | 'personalContactInfo'
  | 'ensuranceList'
  | 'secondBike'
  | 'personalInfo'
  | 'bikeInput'
  | 'detailedBikeInput'
  | 'requestPriceSubmit'
  | '';

export const navigateTo = (target: EnsPage) => async (dispatch) => {
  await dispatch({ type: 'NAVIGATED', target });
  focusTopSelect();
  trackByName(`nav_${target}`);
};
