import styled from 'styled-components';

const Rechtbelehrungscontainer = styled.div`
  border: 1px solid #00000010;
  overflow: auto;
  height: 12em;
  min-height: 8em;
  margin-bottom: 10px;
  padding: 10px;
  list-style-type: disc;
  list-style-position: outside;
  resize: vertical;

  ul {
    padding-inline-start: 2em;
  }

  li {
    margin-bottom: 1em;
    white-space: pre-line;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default Rechtbelehrungscontainer;
