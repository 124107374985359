import { getQueryStrings } from '@/actions/shared/QueryParams';
import { DeepPartialExcept } from '@/actions/types/lib/DeepPartial';
import { getAutoloadEnsurances } from '@/features/AutoloadEns';
import { useAppSelector } from '@/hooks';
import { useMemo } from 'react';
import { DeepPartial } from 'redux';

declare let window: any;

let isOfferRequestProcess = null;

export const useIsOfferRequestProcessProps = (): Parameters<typeof getIsOfferRequestProcess>[0] => {
  const presetIdData = useAppSelector((state) => state.options.presetIdData);
  return useMemo(() => ({ options: { presetIdData } }), [presetIdData]);
};

export const useIsOfferRequestProcess = () => {
  const props = useIsOfferRequestProcessProps();
  return getIsOfferRequestProcess(props);
};

export const getIsOfferRequestProcess = (
  state: DeepPartialExcept<RootState, { options: { presetIdData } }>
): boolean => {

  // Wenn autoload gesetzt ist, darf diese Modus nie aktiv sein
  // Das wir von den Angeboten benutzt. Wenn ein Angebot aus dem offerRequestProcess, welcher per presetId gesetzt wurde,
  // dann würde man auch im geöffneten Angebot den offerRequestProcess Modus haben und kommt nie zum eigentlichen Angebot.
  if (getAutoloadEnsurances(state)) return false;

  if (state.options.presetIdData?.ensCalcMode === 'offerRequestProcess') return true;

  const queryStrings = getQueryStrings();

  return Object.keys(queryStrings).includes('offerRequestProcess') || window?.ensOptions?.offerRequestProcess;
};

type getOfferRequestSettingsState = DeepPartialExcept<RootState, { options: { presetIdData } }>;
const _getOfferRequestSettings = () => {
  const queryStrings = getQueryStrings();
  return {
    offerRequestNoSettings:
      Object.keys(queryStrings).includes('offerRequestNoSettings') ||
      !!window?.ensOptions?.offerRequestNoSettings ||
      false,
  };
};

let offerRequestSettings: ReturnType<typeof _getOfferRequestSettings> = null;
export const getOfferRequestSettings = (state?: getOfferRequestSettingsState) => {
  if (offerRequestSettings == null) {
    offerRequestSettings = _getOfferRequestSettings();
  }

  // Wenn der Wert aus der PresetID kommt, sollen die Einstellungen nicht angezeigt werden
  // https://app.asana.com/0/1174272655772669/1207019953941243/f
  offerRequestSettings.offerRequestNoSettings ||= state?.options?.presetIdData?.ensCalcMode === 'offerRequestProcess';

  return offerRequestSettings;
};

export const useOfferRequestSettings = () => {
  const presetIdData = useAppSelector((state) => state.options.presetIdData);

  return useMemo(() => getOfferRequestSettings({ options: { presetIdData } }), [presetIdData]);
};

window.getOfferRequestSettings = _getOfferRequestSettings;
