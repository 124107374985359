import React from 'react';
import { Button, Container, Grid, Text } from '@mantine/core';

import { ButtonLink } from '@unserkunde/enscompare-components/src/components/components';
import InputWrapperAutowrap from '@unserkunde/enscompare-components/src/components/inputs/InputWrapperAutowrap';

import { FaAngleLeft } from 'react-icons/fa';
import { useEnsOverviewStyles } from './OverviewControls/EnsuranceOverview';
import DynamicEntryQuestion, { EntryQuestionContainer } from './shared/DynamicEntryQuestion';
import { HookedTextInput, HookedTextarea } from './shared/HookedInputs';
import { useAppDispatch, useAppSelector, useDynamicFields } from '@/hooks';
import { NavigateTopMarker, backToEnsList, submitPriceRequest } from '@/actions/progress';

function TopButtonRow() {
  const dispatch = useAppDispatch();
  const onGoBack = React.useCallback(() => dispatch(backToEnsList()), [dispatch]);
  const { classes } = useEnsOverviewStyles();

  return (
    <Container className={classes.topButtonRow}>
      <ButtonLink
        leftIcon={<FaAngleLeft />}
        onClick={onGoBack}>
        Zurück
      </ButtonLink>
    </Container>
  );
}

function BottomButtonRow() {
  const dispatch = useAppDispatch();
  const onGoBack = React.useCallback(() => dispatch(backToEnsList()), [dispatch]);
  const onSubmit = React.useCallback(() => dispatch(submitPriceRequest()), []);
  const { classes } = useEnsOverviewStyles();

  return (
    <Container className={classes.topButtonRow}>
      <Grid align="center">
        <Grid.Col
          xs={12}
          sm={6}>
          <ButtonLink onClick={onGoBack}>
            <FaAngleLeft />
            &nbsp; Zurück
          </ButtonLink>
        </Grid.Col>
        <Grid.Col
          xs={12}
          sm={6}>
          <Button
            color="secondary"
            onClick={onSubmit}
            fullWidth>
            Anfrage absenden
          </Button>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

export const PriceRequestSubmit = () => {
  const selectedOffer = useAppSelector((state) => state.checkout.offer);

  const entryQuestions = useDynamicFields('priceRequestForm');

  return (
    <>
      <NavigateTopMarker />
      <TopButtonRow />
      <Container>
        <EntryQuestionContainer>
          <InputWrapperAutowrap label="Angefragter Tarif">
            <Text>{selectedOffer.displayName}</Text>
          </InputWrapperAutowrap>

          <HookedTextInput
            field="email"
            label="E-Mail"
            autofillName="email"
            type="email"
          />

          <HookedTextarea
            field="comment"
            label="Deine Mitteilung"
          />

          {entryQuestions.map((eo, i) => (
            <DynamicEntryQuestion
              key={i}
              setting={eo}
            />
          ))}

          <InputWrapperAutowrap label=" ">
            Deine Berechnungsangaben werden uns automatisch übermittelt
          </InputWrapperAutowrap>
        </EntryQuestionContainer>
        <BottomButtonRow />
      </Container>
    </>
  );
};
