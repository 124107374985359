import { useEffect } from 'react';
import { useAppSelector } from './hooks';
import { isExternTrackingEnabled } from './actions/tracking';

declare let window: any;

export default () => {
  // Aktuell deaktiviert
  return null;

  const instanceMode = useAppSelector((state) => state.ensfields.ens_instance_mode);

  if (!isExternTrackingEnabled()) return null;

  useEffect(() => {
    if (instanceMode !== 'bike') return;

    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 1209976, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    
  }, [instanceMode]);

  return null;
};
