import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container as RGSContainer } from 'react-grid-system';
import { format } from 'date-fns';
import { MdModeEdit } from 'react-icons/md';

import { Accordion, Button, Container, Grid, Space } from '@mantine/core';

import { FaArrowRight } from 'react-icons/fa';

import Frame from '@unserkunde/enscompare-components/src/components/layout/Frame';
import Price2 from '@unserkunde/enscompare-components/src/components/feedback/Price2';
import { ButtonLink } from '@unserkunde/enscompare-components/src/components/components';
import Antragsaenderung from '../Antragsaenderung';
import { getFamilienraederValues } from '../shared/Ensfeatures';
import { changeUserInput } from '../../actions/form';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useInsuranceObjectName } from '../shared/InstanceNaming';
import { usePriceProps } from '../ensuranceList/EnsuranceItem';
import { EnsIcon } from '../shared/AvailalbeEnsIcons';
import InstanceModeSwitch from '../shared/Switches/InstanceModeSwitch';
import ProductDocs from './ProductDocs';
import ZusammenfassungFields from './ZusammenfassungFields';
import ZusammenfassungCoreInfos from './ZusammenfassungCoreInfos';
import DynamicZiFields from './DynamicZiFields';
import Zi from './ZusammengassungRow';
import Rechtsbelehrung from './Rechtsbelehrung';
import { useIsSize } from '@/hooks/theme';
import { navigateTo, submitZusammenfassung, goBackOnZusammenfassung } from '@/actions/progress';
import { getAntragsaenderungState } from '@/features/Antragsaenderung';
import { useBrowsersBack } from '@/features/BrowserNavigationHandler';
import { ZusammenfassungPriceDisplay } from './ZusammenfassungPriceDisplay';
import { ScrollIntoView } from '../shared/ScrollInfoView';
import { useEnsProcessContext } from '@/processes/processDefinition/DefaultEnsProcessContext';

const parse = {
  bool: (o) => o === 'true' || o === true,
};

const formats = {
  money: (o) => `${o} €`,
  bool: (o) => (parse.bool(o) ? 'Ja' : 'Nein'),
  date: (o) => (o ? format(new Date(o), 'dd.MM.yyyy') : ''),
  mapByArray: (arr) => (o) => {
    for (const i of arr) {
      if (i[1] === o) return i[0];
    }
    return '';
  },
};

const EditHeader = ({ children, onClick, canEdit = true, style = { marginTop: '3em' } }) => (
  <Row style={style}>
    <Col>
      <h3
        onClick={canEdit ? onClick : null}
        style={{
          fontSize: `${14 * 1.5}px`,
          ...(canEdit ? { cursor: 'pointer' } : {}),
        }}>
        {children} {canEdit ? <MdModeEdit /> : null}
      </h3>
    </Col>
  </Row>
);

const BikeZusammenfassung = ({ options, navigateTo, bikeUserData, canEdit = true, bikeIndex, bikeId }) => {
  const deviceKey = useAppSelector((state) => state.devicemode.devkey);

  const insuranceObjectName = useInsuranceObjectName();

  return (
    <>
      <EditHeader
        onClick={() => {}}
        canEdit={false}>
        Dein {insuranceObjectName} {bikeIndex}
      </EditHeader>

      <DynamicZiFields
        bikeId={bikeId}
        position={'objectEntryQuestions'}
      />
      <DynamicZiFields
        bikeId={bikeId}
        position={'objectQuestions'}
      />
    </>
  );
};

const ZusammenfassungRow = ({ userData, options, navigateTo, offer, canEdit = true }) => {
  return (
    <>
      <EditHeader
        onClick={() => {}}
        canEdit={false}
        style={null}>
        Persönliche Informationen
      </EditHeader>

      <Zi
        data={['Firma', userData.firma]}
        c={userData.isFirma}
      />

      <Zi
        data={[
          userData.isFirma ? 'Ansprechpartner' : 'Name',
          `${userData.anrede} ${userData.forename} ${userData.lastname}`,
        ]}
      />

      <Zi data={['Adresse', `${userData.street} ${userData.street_number}`]} />
      <Zi data={['', `${userData.plz} ${userData.city}`]} />
      <Zi data={['Email', userData.email]} />
      <Zi data={['Telefon', userData.phone]} />
      <Zi
        data={['Geburtstag', userData.birthday, formats.date]}
        c={!userData.isFirma}
      />

      <DynamicZiFields position={'entryQuestionsTop'} />
      <DynamicZiFields position={'entryQuestions'} />
      <DynamicZiFields position={'entryQuestionsMore'} />

      <EditHeader
        onClick={() => {}}
        canEdit={false}>
        Bezahlung
      </EditHeader>

      <Zi data={['Iban', userData.iban]} />
      <Zi data={['Kontoeigentümer', userData.ibanOwner]} />
      <Zi data={['Kommentar', userData.comment]} />
      <Zi data={['Versicherungsbeginn', userData.ensuranceStartDate, formats.date]} />
      <Zi
        data={['Sofortschutz (+3,5€ einmalig)', userData.sofortschutz, formats.bool]}
        c={options.includes('versicherungsbeginnMonat')}
      />

      <InstanceModeSwitch disable={'kv'}>
        <Zi
          data={[
            'Mindestvertragslaufzeit',
            offer.contractDurationYears + ' Jahr' + (offer.contractDurationYears == 1 ? '' : 'e'),
          ]}
        />
      </InstanceModeSwitch>

      <Zi
        data={['Vorversicherung vorhanden', userData.vorversicherung, formats.bool]}
        c={!options.includes('no_vorversicherung')}>
        <Zi
          data={['Vorversicherung Name', userData.vorversicherung_name]}
          c={userData.vorversicherung}
        />
        <Zi
          data={['Vorversicherung Scheinnummer', userData.vorversicherung_scheinnummer]}
          c={userData.vorversicherung}
        />
        <Zi
          data={['Gekündigt durch', userData.vorversicherung_gekuendigtdurch]}
          c={userData.vorversicherung}
        />
      </Zi>

      <InstanceModeSwitch enable={'bike'}>
        <>
          <Zi
            data={[
              'Weitere Familienfahrräder versichern',
              userData.ammerlaenderFamilienraeder,
              formats.mapByArray(getFamilienraederValues()),
            ]}
            c={options.includes('ammerlaenderFamilienraeder')}
          />
        </>
      </InstanceModeSwitch>

      {Object.keys(userData.bikes).map((bikeId, bikeIndex) => (
        <BikeZusammenfassung
          key={bikeId}
          bikeUserData={userData.bikes[bikeId]}
          options={options}
          canEdit={canEdit}
          navigateTo={navigateTo}
          bikeIndex={bikeIndex + 1}
          bikeId={bikeId}
        />
      ))}
    </>
  );
};

function ButtonRow({ topMode }) {
  const reverseOrder = useIsSize('sm');

  const [submitted, setSubmitted] = useState(false);

  const dispatch = useAppDispatch();
  const onSubmit = useCallback(() => {
    setSubmitted(true);
    dispatch(submitZusammenfassung());
  }, []);
  const onGoBack = useCallback(() => dispatch(goBackOnZusammenfassung()), []);

  useBrowsersBack(onGoBack, !topMode);

  const disableButtons = !!useEnsProcessContext();

  if (disableButtons) return null;

  return (
    <Container
      pt={topMode ? 'md' : undefined}
      px={0}>
      <Grid align='center'>
        <Grid.Col
          order={reverseOrder ? 1 : 3}
          xs={12}
          sm={6}>
          <ButtonLink
            disabled={submitted}
            onClick={onGoBack}
            fullWidth={!topMode}>
            <EnsIcon
              icon='FaArrowLeft'
              useAppColor={true}
            />
            &nbsp; Zurück
          </ButtonLink>
        </Grid.Col>
        <Grid.Col
          order={2}
          xs={12}
          sm={6}>
          <Button
            disabled={submitted}
            color='secondary'
            fullWidth
            onClick={onSubmit}>
            Zahlungspflichtig bestellen&nbsp;&nbsp;
            <FaArrowRight color='white' />
          </Button>
        </Grid.Col>
      </Grid>
      {topMode ? null : <Space h='xl' />}
    </Container>
  );
}

const Zusammenfassung = (props) => {
  return (
    <>
      <ScrollIntoView />
      <ButtonRow topMode={true} />
      <Container>
        <Frame>
          <ZusammenfassungCoreInfos />
          <Accordion defaultValue='zusammenfassung'>
            <Accordion.Item value='zusammenfassung'>
              <Accordion.Control>Zusammenfassung</Accordion.Control>
              <Accordion.Panel py={20}>
                <RGSContainer>
                  <ZusammenfassungRow {...props} />
                </RGSContainer>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          <ProductDocs />
          <ZusammenfassungFields />

          {!getAntragsaenderungState() ? null : <Antragsaenderung />}

          <Space h={'md'} />
          <ZusammenfassungPriceDisplay />
          <Rechtsbelehrung />
        </Frame>
        <Space h='xl' />
      </Container>
      <ButtonRow topMode={false} />
    </>
  );
};

const ZusammenfassungNoEditView = (props) => {
  return (
    <>
      <Accordion defaultValue='zusammenfassung'>
        <Accordion.Item value='zusammenfassung'>
          <Accordion.Control>Ihre Daten</Accordion.Control>
          <Accordion.Panel py={20}>
            <RGSContainer>
              <ZusammenfassungRow
                {...props}
                canEdit={false}
              />
            </RGSContainer>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  options: state.options.options || [],
  offer: state.checkout.offer,
  ensfields: state.ensfields,
  devicekey: state.devicemode.devkey,
  voucherInfo: state.voucher,
});

const mapDispatchToProps = (dispatch) => {
  return {
    navigateTo: (target) => dispatch(navigateTo(target)),
    onChangeInput:
      (field, selector = null, bikeId = null) =>
      (...changeProps) =>
        dispatch(changeUserInput(field, (selector || ((o) => o))(...changeProps), bikeId)),
  };
};

export const ZusammenfassungNoEdit = connect(mapStateToProps, mapDispatchToProps)(ZusammenfassungNoEditView);

export default connect(mapStateToProps, mapDispatchToProps)(Zusammenfassung);
