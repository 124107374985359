import React, { ComponentType, useCallback, useMemo } from 'react';

import Progressbar from '@unserkunde/enscompare-components/src/components/feedback/Progressbar';

import { Anchor, Container, Group } from '@mantine/core';
import { IsPremiumserviceProcess } from './premiumserviceProcess/PremiumserviceProcessInfo';
import EnsuranceOverview from 'forms/OverviewControls/EnsuranceOverview';
import Success from 'forms/success/Success';
import EnsuranceList from 'forms/ensuranceList/EnsuranceList';
import AddSecondBike from 'forms/OverviewControls/AddSecondBike';
import AngebotFertig from 'forms/angebot/AngebotCompleted';
import VoucherSelectionPage from 'forms/InspectionVoucher/VoucherSelectionPage';
import { useIsOfferRequestProcess } from 'processes/offerRequestProcess/IsOfferRequestProcess';
import { EnsPage } from '@/actions/progress';
import { PriceRequestSubmit } from '@/forms/PriceRequestSubmit';
import { AutoloadStandalone } from '@/features/AutoloadEns';
import { useAppSelector } from '@/hooks';
import { FrontendRedirectEntry } from '@/reducer/ensfields';
import { getQueryStrings } from '@/actions/shared/QueryParams';
import { OverlayDisplay } from './overlay/OverlayDisplay';
import { DefaultEnsProcessRenderer } from './processDefinition/DefaultEnsProcessRenderer';

import { FluentProgress } from './defaultFluentProcess/FluentProgress';

const OfferRequestProcess = React.lazy(() => import('processes/offerRequestProcess/OfferRequestProcess'));
const OfferRequestProcessSettings = React.lazy(
  () => import('processes/offerRequestProcess/OfferRequestProcessSettings')
);

const FluentRenderer = () => {
  const target = useAppSelector((state) => state.router);

  return (
    <DefaultEnsProcessRenderer
      target={target as keyof typeof FluentProgress.processSteps}
      processDefinition={FluentProgress}
    />
  );
};

const useControl = (target: EnsPage) => {
  const isOfferRequestProcess = useIsOfferRequestProcess();

  const overlayName = useAppSelector((state) => state.overlay.overlayName);

  return useMemo(() => {
    let CurrentControl: ComponentType = FluentRenderer;
    let stepperProgress: number | false = false;
    let stepperTitle: string | false = false;
    let showRedirect = false;

    if (isOfferRequestProcess) {
      CurrentControl = OfferRequestProcess;
      stepperProgress = false;
    } else {
      stepperProgress = 5;
      stepperTitle = 'Berechnung';
    }

    if (target === 'secondBike') {
      CurrentControl = FluentRenderer;
      stepperProgress = 25;
    } else if (target === 'ensuranceList') {
      CurrentControl = EnsuranceList;
      stepperTitle = 'Angebote';
      stepperProgress = 40;
    } else if (target === 'personalInfo' || target === 'personalContactInfo') {
      CurrentControl = FluentRenderer;
      stepperTitle = 'Antrag';
      stepperProgress = 60;
    } else if (target === 'paymentInfo') {
      CurrentControl = FluentRenderer;
      stepperTitle = 'Antrag';
      stepperProgress = 70;
    } else if (target === 'bikeinfo') {
      CurrentControl = FluentRenderer;
      stepperProgress = 80;
      stepperTitle = 'Antrag';
    } else if (target === 'zusammenfassung') {
      CurrentControl = FluentRenderer;
      stepperProgress = 90;
      stepperTitle = 'Angaben überprüfen';
    } else if (target === 'requestPriceSubmit') {
      CurrentControl = PriceRequestSubmit;
      stepperProgress = 90;
      stepperTitle = 'Angebotsanfrage';
    } else if (target === 'angebotfertig') {
      CurrentControl = AngebotFertig;
      stepperProgress = 100;
      stepperTitle = 'Fertig - So geht es weiter';
    } else if (target === 'inpsectionVoucher') {
      CurrentControl = VoucherSelectionPage;
      stepperProgress = 10;
    } else if (target === 'offerRequestProcessSettings') {
      CurrentControl = OfferRequestProcessSettings;
      stepperProgress = false;
    } else if (target === 'order_success') {
      CurrentControl = Success;
      stepperProgress = 100;
      stepperTitle = 'Fertig - So geht es weiter';
    } else {
      //Ensurance Overview
      showRedirect = true;
    }

    if (isOfferRequestProcess || IsPremiumserviceProcess()) {
      stepperProgress = false;
      stepperTitle = false;
    }

    if (overlayName) CurrentControl = OverlayDisplay;

    return [CurrentControl, stepperProgress, stepperTitle, showRedirect] as const;
  }, [target, overlayName, isOfferRequestProcess]);
};

const InstanceRedirectItem = (props: { item: FrontendRedirectEntry }) => {
  const handler = useCallback(() => {
    window.ensRerenderWithApiSource(props.item.instanceRootUrl);
  }, [props.item]);

  return <Anchor onClick={handler}>{props.item.displayName}</Anchor>;
};

const InstanceRedirectControl = () => {
  const redirects = useAppSelector((state) => state.ensfields.frontendInstanceRedirect);

  if (!redirects || redirects.length === 0) return null;

  if (!getQueryStrings()?.showInstanceRedirect) return null;

  return (
    <Group position='right'>
      {redirects.map((redirect, index) => (
        <InstanceRedirectItem
          item={redirect}
          key={index}
        />
      ))}
    </Group>
  );
};

const DefaultProgress = (props) => {
  const { target } = props;

  const [CurrentControl, stepperProgress, stepperTitle, showRedirect] = useControl(target);

  const progressProps = useMemo(
    () => ({
      progress: { value: stepperProgress },
      title: stepperTitle,
      //showRedirect,
    }),
    [stepperProgress, stepperTitle]
  );

  return (
    <>
      {stepperProgress === false ? null : (
        <AutoloadStandalone hide>
          <Container
            px='0'
            fluid>
            {showRedirect && <InstanceRedirectControl />}
            <Progressbar {...progressProps} />
          </Container>
        </AutoloadStandalone>
      )}
      <CurrentControl />
    </>
  );
};

export default DefaultProgress;
