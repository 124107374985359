import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer/index';
import { getQueryStrings } from './actions/shared/QueryParams';

export function storeCreator(startValue: any = {}) {
  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: { warnAfter: 128 },
        serializableCheck: { warnAfter: 128 },
      }),
    reducer: rootReducer,
    // @ts-ignore
    trace: process.env.NODE_ENV !== 'production' || getQueryStrings()?.devmode === 'true',
    traceLimit: 25,
    devTools: process.env.NODE_ENV !== 'production' || getQueryStrings()?.devmode === 'true',
    preloadedState: window.ensOptions?.__ens_start_store || startValue,
  });
}

export let store = storeCreator();

export const resetStore = () => {
  store = storeCreator();
}

export type AppStoreType = typeof store;
export type AppStateGetterType = AppStoreType['getState'];

export type AppDispatch = typeof store.dispatch;

/*
const isSelectedHost = false;
if (isSelectedHost) {
  store.subscribe(() => {
    localStorageService.set(lsKeys.store, store.getState());
  });
}
*/

export default store;
