import { MantineSize, MantineTheme, useMantineTheme } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import React, { useContext, useMemo } from 'react';
import { createContext, forwardRef } from 'react';
import { useTheme } from 'styled-components';

const EnsContainerSizeObserverContext = createContext<MantineSize | null>(null);

export const useIsContainerSize = (observerSize: MantineSize) => {
  const value = useContext(EnsContainerSizeObserverContext);

  return useMemo(() => {
    if (value === observerSize) return true;
    if (observerSize === 'xs') return true;

    if (observerSize === 'sm' && ['sm', 'md', 'lg', 'xl'].includes(value)) return true;

    if (observerSize === 'md' && ['md', 'lg', 'xl'].includes(value)) return true;

    if (observerSize === 'lg' && ['lg', 'xl'].includes(value)) return true;

    if (observerSize === 'xl' && ['xl'].includes(value)) return true;

    return false;
  }, [value, observerSize]);
};

export const useContainerSize = () => {
  return useContext(EnsContainerSizeObserverContext);
};

export const EnsContainerSizeObserverProvider = ({ children }) => {
  const [ref, rect] = useResizeObserver();

  const theme = useMantineTheme();

  const value: MantineSize = useMemo(() => {
    const width = rect?.width || 0;

    if (width > theme.breakpoints.xl) return 'xl';
    if (width > theme.breakpoints.lg) return 'lg';
    if (width > theme.breakpoints.md) return 'md';
    if (width > theme.breakpoints.sm) return 'sm';

    return 'xs';
  }, [rect?.width]);

  return (
    <div ref={ref}>
      <EnsContainerSizeObserverContext.Provider value={value}>{children}</EnsContainerSizeObserverContext.Provider>
    </div>
  );
};
