import { navigateTo } from '../shared/navigateTo';
import { getUrl } from '../data';
import { track } from '../tracking/index';

import { getAntragsaenderungState } from '../../features/Antragsaenderung';
import Audit from './audit';
import { addCustomFilter, addUserDataPostFields } from './sharedPostField';

const submit = () => {
  return async (dispatch, getState) => {
    const state: RootState = getState();

    if (!state.checkout.name) {
      alert('Fehler: Es wurde keine gewählte Versicherung gefunden. Bitte starten Sie den Prozess erneut.');
      return;
    }

    dispatch({ type: 'SUBMIT_LOADING' });
    const request = JSON.parse(JSON.stringify(state.userData));
    const checkout = state.checkout;
    const formData = new FormData();

    addUserDataPostFields(state, request, formData);
    addCustomFilter(state, formData);

    formData.append('ensName', checkout.name);
    formData.append('offer', checkout?.offer?.offer.toString());
    // @ts-ignore
    formData.append('offerPaymentPeriod', checkout.offer.period);
    // @ts-ignore
    formData.append('period', checkout.offer.period);
    formData.append('vst', checkout?.offer?.vst.toString());
    if (getAntragsaenderungState()) {
      // @ts-ignore
      formData.append('antragsaenderung', getAntragsaenderungState());
    }

    const result = await fetch(getUrl('/wp-admin/admin-post.php?action=submit_ensurance_offer'), {
      body: formData,
      method: 'post',
    })
      .then((o) => o.text())
      .catch(() => {
        return 'Error fetching Result';
      });

    let parsedResult: any = { success: false };
    try {
      parsedResult = JSON.parse(result);
    } catch {
      parsedResult = { success: false, message: 'Error parsing Result' };
    }

    if (!parsedResult.success) {
      Audit({ result, message: 'Client got Error on Submit' }, 0);
    } else {
      track({
        name: 'ens_order_success',
        ensName: checkout.name,
        devicemode: state.devicemode.devkey,
      });
    }

    dispatch({
      type: 'SUBMIT_COMPLETED',
      success: parsedResult.success,
      text: parsedResult.text,
      notifications: parsedResult.notifications,
      voucherInfoText: parsedResult.voucherInfoText,
      orderGroup: parsedResult.orderGroup,
      stripe_checkout: parsedResult.stripe_checkout,
    });
    dispatch(navigateTo('order_success'));
  };
};
export default submit;
