import { useEffect } from 'react';

let currentBackHandler = null;

export const useBrowsersBack = (backHandler, disable = false) => {
  useEffect(() => {
    if (disable) return () => {};
    currentBackHandler = backHandler;

    return () => {
      currentBackHandler = null;
    };
  }, [backHandler, disable]);
};

export const BrowserNavigationHandler = () => {
  useEffect(() => {
    if (window.location.href === 'about:srcdoc') return () => {};

    window.history.pushState({}, '');

    const popHandler = (e) => {
      window.history.pushState({}, '');
      if (currentBackHandler) currentBackHandler();
    };

    window.addEventListener('popstate', popHandler);

    return () => {
      window.removeEventListener('popstate', popHandler);
      window.history.back();
    };
  }, []);

  return null;
};

export const usePushedQueryParameter = (key, value) => {
  useEffect(() => {
    if (value) {
      const url = new URL(window.location.href);
      url.searchParams.set(key, value);
      window.history.pushState({}, '', url.toString());
    }
  }, [key, value]);
}