import { useEffect } from 'react';
import { TrackerEventType } from './TrackerType';
import { track } from './index';

export const PageTracking = (props: TrackerEventType) => {
  useEffect(() => {
    track(props);
  }, []);

  return null;
};
