// import './wdyr';
import React, { useMemo } from 'react';
import * as ReactIcons from 'react-icons';
import { createStyles } from '@mantine/core';

import { Provider } from 'react-redux';
import Router from './Router';
import Predef from './features/Predef';
import Preset from './features/Preset';
import Ensfields from './features/Ensfields';
import RefererPing from './features/RefererPing';
import AutoloadEns from './features/AutoloadEns';
import ImpressumControl from './forms/shared/impressumControl';
import BrowserCheck from './features/Browsercheck';
import GlobalErrorHandler from './features/GlobalErrorHandler';
import { selectorIsInitialLoading, useAppSelector } from './hooks';
import Initializer from './features/Initializer';
import store from './store';
import EnsThemeProvider from './EnsThemeProvider';
import Hotjar from './Hotjar';
import EnsLoadingOverlay from './forms/shared/loading/EnsLoadingOverlay';
import { CustomCss } from './features/CustomCss';
import Tracking from '@/actions/tracking/Tracking';
import DevmodeDisplay from './features/DevmodeDisplay';
import { GetIsDevMode } from './forms/shared/IsDevMode';
import { BrowserNavigationHandler } from './features/BrowserNavigationHandler';
import { AutoloadStandaloneFeature } from './features/AutoloadStandaloneFeature';
import { DefaultCustomProducts } from './features/DefaultCustomProducts';
import { EnsContainerSizeObserverProvider } from './forms/shared/EnsContainerObserver';
import { UncompletedOrderSaver } from './features/UncompletedOrderSaver';

const useAppStyle = createStyles(() => {
  return {
    fullscreen: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999999,
      overflowY: 'auto',
      backgroundColor: 'white',
    },
    wrapper: {
      maxWidth: window?.ensOptions?.fullWidth ? '100% !important' : '1170px !important',
      //backgroundColor: 'white'
    },
  };
});

function AppContent() {
  const isInitialLoading = useAppSelector(selectorIsInitialLoading);

  const iconContext = useMemo(() => ({ color: '#A4D0F8', className: 'react-icons' }), []);
  const { classes } = useAppStyle();

  const Renderer = useMemo(
    () =>
      !window?.ensOptions?.isFullscreen
        ? ({ children }) => children
        : ({ children }) => <div className={classes.fullscreen}>{children}</div>,
    []
  );

  return (
    <ReactIcons.IconContext.Provider value={iconContext}>
      <Renderer>
        <div className={`ens_app_root ${classes.wrapper}`}>
          <EnsContainerSizeObserverProvider>
            <CustomCss />
            {GetIsDevMode() && <DevmodeDisplay />}
            <BrowserCheck />
            <Initializer />
            <Router />
            <Predef />
            <Preset />
            <UncompletedOrderSaver />
            <Ensfields />
            <Tracking />
            <GlobalErrorHandler />
            <AutoloadEns />
            <DefaultCustomProducts />
            <AutoloadStandaloneFeature />
            <Hotjar />
            <RefererPing />
            <BrowserNavigationHandler />
            {isInitialLoading ? null : <ImpressumControl />}
            <EnsLoadingOverlay />
          </EnsContainerSizeObserverProvider>
        </div>
      </Renderer>
    </ReactIcons.IconContext.Provider>
  );
}

export function App({ mantineProviderProps = null }) {
  return (
    <EnsThemeProvider {...mantineProviderProps}>
      <AppContent />
    </EnsThemeProvider>
  );
}

function AppWithStore({ mantineProviderProps = null }) {
  return (
    <Provider
      store={store}
      noopCheck='never'>
      <App mantineProviderProps={mantineProviderProps} />
    </Provider>
  );
}

export default AppWithStore;
