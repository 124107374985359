import React, { useMemo } from 'react';
import { connect } from 'react-redux';

// @ts-ignore
import DOMPurify from 'dompurify';
import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';
import { Alert, Box, createStyles } from '@mantine/core';
import { EnsIcon } from '../AvailalbeEnsIcons';
import styles from './style.css?inline';
import { TextStyle } from './sizes';
import { addInlineStyle } from '@/indexElements';

addInlineStyle(styles);

const useStyle = createStyles((theme: MantineEnsTheme) => {
  return {
    button: {
      backgroundColor: theme.colors.secondary[theme.primaryShade],
      '&:hover': {
        backgroundColor: theme.colors.secondary[theme.primaryShade - 1]
      },
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 10px #00000044',

        '&:active': {
          boxShadow: 'none'
        }
      },

      '&.ens_button_ammerlaender': {
        backgroundColor: '#009757',
        '&:hover': {
          backgroundColor: '#11ad6b'
        }
      },
      '&.secondary': {
        backgroundColor: theme.colors.primary[theme.primaryShade],
        '&:hover': {
          backgroundColor: theme.colors.primary[theme.primaryShade - 1]
        }
      }
    }
  };
});

export function ButtonBase({
  display,
  ensButtonClass,
  className,
  marked,
  secondary,
  dispatch,
  disabled = false,
  success = false,
  style,
  children,
  ...props
}) {
  const { classes } = useStyle();

  return (
    <button
      type="button"
      className={
        // eslint-disable-next-line prefer-template
        classes.button +
        ' ens_button' +
        (disabled ? ' disabled ' : '') +
        (success ? ' success ' : '') +
        ` ${ensButtonClass} ` +
        (display ? ` ens_button_${display}` : '') +
        ' ' +
        (secondary ? 'secondary ' : ' ') +
        (className || '')
      }
      style={{ ...TextStyle, ...style }}
      {...props}>
      {children}
    </button>
  );
}

const mapStateToProps = ({ options }) => ({
  ensButtonClass: options.settings.ensButtonClass || ''
});
export const Button = connect(mapStateToProps)(ButtonBase);

export function ToggleButton({ checked, fadeOnChecked = true, ...props }) {
  const style = useMemo(() => (checked && fadeOnChecked ? { opacity: '0.5' } : null), [checked, fadeOnChecked]);

  return (
    <Button
      success={checked}
      style={style}
      display="small"
      {...props}
    />
  );
}

export function LinkButton(props) {
  return (
    <button
      className={`ens_linkbutton ${props.className}`}
      {...props}
    />
  );
}

export type Hr_Classes = 'large' | string | null | undefined;

export function Hr({ className = '', ...props }: { className?: Hr_Classes }) {
  return (
    <div
      className={`ens_hr ${className}`}
      {...props}
    />
  );
}

export function HrRow(props) {
  return (
    <Row>
      <Col>
        <Hr {...props} />
      </Col>
    </Row>
  );
}

type DynamicBoxType = 'success' | 'error' | 'warning' | 'info' | 'none';

export function DynamicBox({ type, ...props }: { type: DynamicBoxType; [x: string]: any }) {
  let Control = InfoBox;
  switch (type) {
    case 'success':
      Control = SuccessBox;
      break;
    case 'error':
      Control = ErrorBox;
      break;
    case 'warning':
      Control = WarningBox;
      break;
    case 'none':
      Control = InvisibleBox;
      break;
    case 'info':
    default:
      Control = InfoBox;
      break;
  }

  return <Control {...props} />;
}

function BoxParentControl({ children }) {
  return <Box sx={(theme) => ({ padding: theme.spacing.sm })}>{children}</Box>;
}

const BoxIconProps = {
  color: '#22222266',
  size: '1.5rem'
};

export function SuccessBox(props) {
  return (
    <BoxParentControl>
      <Alert
        icon={
          <EnsIcon
            icon="MdCheck"
            {...BoxIconProps}
          />
        }
        color="teal"
        {...props}
      />
    </BoxParentControl>
  );
}

export function InvisibleBox(props) {
  return <BoxParentControl {...props} />;
}

export function InfoBox(props) {
  return (
    <BoxParentControl>
      <Alert
        icon={
          <EnsIcon
            icon="MdInfoOutline"
            {...BoxIconProps}
          />
        }
        color="cyan"
        {...props}
      />
    </BoxParentControl>
  );
}

export function WarningBox(props) {
  return (
    <BoxParentControl>
      <Alert
        icon={
          <EnsIcon
            icon="MdOutlineWarningAmber"
            {...BoxIconProps}
          />
        }
        color="orange"
        {...props}
      />
    </BoxParentControl>
  );
}

export function ErrorBox(props) {
  return (
    <BoxParentControl>
      <Alert
        icon={
          <EnsIcon
            icon="MdOutlineRemoveCircleOutline"
            {...BoxIconProps}
          />
        }
        color="red"
        {...props}
      />
    </BoxParentControl>
  );
}

const DefaultPureHtmlContainer = styled.div`
  background: transparent;
  color: inherit;
  border: none;
  white-space: ${(props) =>
    /* @ts-ignore */
    props.preLine ? 'pre-line' : 'normal'};

  a {
    word-break: ${(props) =>
      /* @ts-ignore */
      props.preLine ? 'break-all' : 'normal'};
  }
`;

const defaultAllowedTags = ['ul', 'li', 'strong', 'em', 'blockquote', 'p'];
const defaultAllowedAttrs = ['target'];

export function PureHtml({ content, container = null, ...props }) {
  const sanitizedContent = DOMPurify.sanitize(content, {
    ADD_TAGS: defaultAllowedTags,
    ADD_ATTR: defaultAllowedAttrs
  });

  const Container = container || DefaultPureHtmlContainer;

  return (
    <Container
      {...props}
      dangerouslySetInnerHTML={{
        __html: sanitizedContent
      }}
    />
  );
}
