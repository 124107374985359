import { getUrl } from '../data';
import { getEnsFilter } from './requestOffers';
import { addCustomFilter, addUserDataPostFields } from './sharedPostField';

export type AngeboAnfordernType = 'default' | 'link';

const angebotAnfordern = (type: AngeboAnfordernType = 'default') => {
  return async (dispatch, getState) => {
    const state = getState();
    const request = JSON.parse(JSON.stringify(state.userData));

    const selectedNames = [...getState().ensCompare.selectedNames];

    const formData = new FormData();

    addUserDataPostFields(state, request, formData);
    addCustomFilter(state, formData);

    // @ts-ignore
    formData.append('selectedNames', selectedNames);
    formData.append('ensFilter', getEnsFilter(getState()));

    dispatch({ type: 'SUBMIT_LOADING' });

    const alertError = (e) => {
      alert('Es ist leider ein Fehler aufgetreten. Ihre Anfrage konnte vermutlich nicht bearbeitet werden.');
      console.log('error', e);
    };

    const fetchUrl =
      type === 'default'
        ? '/wp-admin/admin-post.php?action=submit_angebot_anfordern'
        : '/wp-json/ens/v1/ensOffer/offerAsLink';

    const result = await fetch(getUrl(fetchUrl), {
      body: formData,
      method: 'post',
    })
      .then((o) => o.json())
      .catch(alertError);

    console.log(result);

    let hasError = false;
    if (result && !result.success) {
      hasError = true;
      alertError(result);
    }

    dispatch({
      type: 'SUBMIT_COMPLETED',
      success: !hasError,
      text: result.text,
      data: result?.data,
    });
  };
};

export default angebotAnfordern;
