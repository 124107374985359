import { EntryQuestionContainer } from '@/forms/shared/DynamicEntryQuestion';
import { HookedCheckboxRow, HookedDateInput, HookedRadioSwitchRow, HookedTextInput } from '@/forms/shared/HookedInputs';
import { OptionCustom, OptionInclude } from '@/forms/shared/Option';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { Text } from '@mantine/core';
import React from 'react';

const anreden: [string, string][] = [
  ['Herr', 'Herr'],
  ['Frau', 'Frau'],
];

const PersonalContactInput = React.memo(() => {
  const isFirma = useAppSelector((state) => state.userData?.isFirma);

  return (
    <>
      <EntryQuestionContainer>
        <OptionInclude on={'withFirma'}>
          <HookedCheckboxRow
            field='isFirma'
            title={'Firma'}
          />
        </OptionInclude>

        {!isFirma ? null : (
          <HookedTextInput
            field='firma'
            label='Name der Firma'
          />
        )}

        <HookedRadioSwitchRow
          field='anrede'
          title='Anrede'
          values={anreden}
          autofillName='sex'
        />

        <HookedTextInput
          field='forename'
          label='Vorname'
          autofillName='given-name'
        />

        <HookedTextInput
          field='lastname'
          label='Nachname'
          autofillName='family-name'
        />

        <HookedTextInput
          field='email'
          label='Email'
          autofillName='email'
          type='email'
        />

        <OptionCustom on={(options) => !isFirma || options.includes('requireFirmaGeburtstag')}>
          <HookedDateInput
            isBday={true}
            field='birthday'
            label='Geburtsdatum'
          />
        </OptionCustom>

        {!window?.ensOptions?.disableUncompletedOrderReminder && (
          <Text ta={'end'}>
            Wenn Sie auf "Weiter" Klicken speichern wir Ihre Antragsdaten für einen späteren Zeitpunkt ab, damit Sie
            erneut einsteigen können
          </Text>
        )}
      </EntryQuestionContainer>
    </>
  );
});

export default PersonalContactInput;
