import styled from 'styled-components';

export const IsBorderless = () => window.ensOptions?.isBorderless;

const ProgressContainer = styled.div`
  border: ${() => (IsBorderless() ? 'initial' : '1px solid #dddddd')};
  background: ${() => (IsBorderless() ? 'white' : '#fcfcfc')};
  padding: 0 0px 5px 0px;
`;

export default ProgressContainer;
