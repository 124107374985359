

export const stylesRoot = document.createElement('div');

export const addInlineStyle = (style: string) => {
  const styleElement = document.createElement('style');
  styleElement.innerHTML = style;
  stylesRoot.appendChild(styleElement);
}

export const emotionCacheRoot = document.createElement('div');

export const reactRootElement = document.createElement('div');