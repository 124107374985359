import React, { useEffect, useMemo } from 'react';
import { confirmEnsuranceOverview, navigateTo } from '../actions/progress';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  getIsOfferRequestProcess,
  useIsOfferRequestProcess,
} from '@/processes/offerRequestProcess/IsOfferRequestProcess';
import { getQueryStrings } from '@/actions/shared/QueryParams';
import { compareDataRequested } from '@/actions/compare';
import { DeepPartialExcept } from '@/actions/types/lib/DeepPartial';
import { GetPredef } from './Predef';
import { GetIsDevMode } from '@/forms/shared/IsDevMode';

let isAutoloaded = false;

export const getIsAutloadStandaloneMode = (...props: Parameters<typeof getAutoloadEnsurances>) => {
  return getAutoloadEnsurances(...props) === 'compare_standalone';
};

export const getAutoloadEnsurances = (state: DeepPartialExcept<RootState, { options: { presetIdData } }>) => {
  const presetIdData = state.options.presetIdData;

  const presetValueOverride = ['compare_standalone'].includes(presetIdData?.ensCalcMode)
    ? presetIdData?.ensCalcMode
    : null;

  const rawAutoloadEnsurances =
    getQueryStrings().ensAutoLoadEnsurances?.toString() ||
    presetValueOverride ||
    window?.ensOptions?.autoLoadEnsurances;

  switch (rawAutoloadEnsurances) {
    case 'false':
      return false;
    case 'compare':
      return 'compare';
    case 'compare_standalone':
      return 'compare_standalone';
    default:
      return !!rawAutoloadEnsurances;
  }
};

export const isCompareStandaloneMode = (...props: Parameters<typeof getAutoloadEnsurances>) => {
  return getAutoloadEnsurances(...props) === 'compare_standalone';
};

export const useAutoloadEnsurances = () => {
  const presetIdData = useAppSelector((state) => state.options.presetIdData);
  return useMemo(() => getAutoloadEnsurances({ options: { presetIdData: presetIdData } }), [presetIdData]);
};

export const useIsStandaloneMode = () => {
  const mode = useAutoloadEnsurances();
  return mode === 'compare_standalone';
};

export const AutoloadStandalone = ({ hide = false, children }: React.PropsWithChildren<{ hide?: boolean }>) => {
  const mode = useAutoloadEnsurances();

  if (mode === 'compare_standalone' && hide) {
    return null;
  }
  if (mode !== 'compare_standalone' && !hide) {
    return null;
  }

  return children;
};

type AutoLoadEnsurancesValue = ReturnType<typeof useAutoloadEnsurances>;

const AutoloadEns = () => {
  const requiredFieldsLoaded = useAppSelector((state) => state.ensfields.requiredFieldsLoaded);

  const dispatch = useAppDispatch();
  const predefState = useAppSelector((state) => state.options.predefState);

  const presetIdState = useAppSelector((state) => state.options.presetIdState);

  const isOfferRequestProcess = useIsOfferRequestProcess();

  const selectedDevicemode = useAppSelector((state) => state.devicemode.devkey);

  const enableAutoload = useAutoloadEnsurances();

  useEffect(() => {
    if (!requiredFieldsLoaded || !presetIdState || !predefState || predefState === 'loading' || isAutoloaded) return;
    if (!enableAutoload || isOfferRequestProcess) return;

    isAutoloaded = true;

    const selectedEnsRaw =
      (getQueryStrings().ensCompareSelectedEns || window.ensOptions?.compareSelectedEns || '').toString() || '';
    const selectedEns = Array.isArray(selectedEnsRaw) ? selectedEnsRaw : selectedEnsRaw.split(',').filter((e) => e);

    const hasPredef = !!GetPredef();

    const loadEnsurances = [true, 'compare'].includes(enableAutoload) || hasPredef;
    const loadComapreMode =
      (selectedEns.length > 0 && (['compare'] as AutoLoadEnsurancesValue[]).includes(enableAutoload)) || hasPredef;

    const compareModeLoader = async () => {
      if (selectedEns.length === 0) {
        console.log("Vergleichsansicht wurde nicht geladen, da keine 'ensCompareSelectedEns' angegeben wurde");
        return;
      }

      selectedEns.forEach((ens) => {
        dispatch({ type: 'COMPARE_ENS_TOGGLE_SELECTED', name: ens });
      });

      await dispatch({ type: 'COMPARE_ENS_ACTIVE_MODE' });
    };

    if (GetIsDevMode())
      console.log('AutoloadEnsInfo', {
        loadEnsurances,
        loadComapreMode,
        enableAutoload,
        selectedEns,
        selectedEnsRaw,
        hasPredef,
      });

    if (loadEnsurances) {
      dispatch(
        confirmEnsuranceOverview([
          false,
          false,
          false,
          {
            skipResetCompareMode: true,
            skipBasicValidation: loadComapreMode,
            selectedNames: selectedEns,
            skipDefaultUserDataLoading: hasPredef,
          },
        ])
      ).then(loadComapreMode ? compareModeLoader : null);
    } else if (loadComapreMode) {
      if (!selectedDevicemode) {
        console.log("Vergleichsansicht wurde nicht geladen, da kein 'devicemode' angegeben wurde");
        return;
      }

      dispatch(compareDataRequested()).then(() => {
        dispatch(navigateTo('ensuranceList'));
        compareModeLoader();
      });
    }
  }, [requiredFieldsLoaded, predefState, selectedDevicemode, enableAutoload, presetIdState, isOfferRequestProcess]);

  return null;
};

export default AutoloadEns;
