import { useEffect } from "react";
import { stylesRoot } from "@/indexElements";


export const CustomCss = () => {


    useEffect(() => {
        if(!window?.ensOptions?.customCssUrl) return;

        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = window.ensOptions.customCssUrl;
        stylesRoot.appendChild(link);
    },[]);


    return null;
};