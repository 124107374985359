import React, { useState } from 'react';

import { Group, Space, Text } from '@mantine/core';
import CompareTableHeader from './CompareTableHeader';
import CompareTableContent from './CompareTableContent';
import CompareHorizontalInputFields from './CompareHorizontalInputFields';
import { useAppSelector } from '@/hooks';
import { AutoloadStandalone, useAutoloadEnsurances } from '@/features/AutoloadEns';
import { useCompareScroll } from '@/actions/compare';
import { CompareTableElementCount } from './CompareTableElementCount';
import { useShowEntryQuestions } from './useShowEntryQuestions';

export const CompareTableGrouped = () => {
  const compDataGroups = useAppSelector((state) => state.ensCompare.compareData || {});
  const selectedInsurances = useAppSelector((state) => state.ensCompare.visibleNames);

  const [onlyDifferences, setOnlyDifferences] = useState(false);

  const compareScroll = useCompareScroll();

  if (!compDataGroups) return null;

  const enableInputFields = useShowEntryQuestions();

  return (
    <div className='compareTable'>
      <div>
        <Space h='md' />

        <AutoloadStandalone>
          <CompareTableElementCount />
        </AutoloadStandalone>

        {enableInputFields && <CompareHorizontalInputFields />}

        {compareScroll.scrollEnable && (
          <Group position='right'>
            <Text>
              {compareScroll.minSelected}-{compareScroll.maxSelected} von {compareScroll.total}
            </Text>
          </Group>
        )}

        <Space h='md' />

        <CompareTableHeader
          topMode={true}
          scrollOnLoad={true}
          disableDifferences={selectedInsurances?.length === 1}
          onlyDifferences={onlyDifferences}
          setOnlyDifferences={setOnlyDifferences}
        />
        <CompareTableContent onlyDifferences={onlyDifferences && selectedInsurances?.length > 1} />
      </div>
      <Space h='md' />
      <CompareTableHeader
        disableDifferences={true}
        onlyDifferences={onlyDifferences}
        setOnlyDifferences={setOnlyDifferences}
      />
    </div>
  );
};

export default CompareTableGrouped;
