import { useAppSelector } from '@/hooks';
import { Box, Chip, Text, ScrollArea, createStyles, Flex } from '@mantine/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import numeral from 'numeral';
import { WheelCapture } from '../shared/WheelCapture';

const useChipStyles = createStyles((theme, _, getRef) => ({
  label: {
    height: 'auto',

    [`& .${getRef('iconWrapper')}`]: {
      display: 'none',
    },
  },
  iconWrapper: {
    ref: getRef('iconWrapper'),
  },
}));

const useStyles = createStyles((theme) => ({
  maskLeft: { maskImage: 'linear-gradient(to right, black 90%, transparent 100%)' },
  maskRight: { maskImage: 'linear-gradient(to right, transparent 0%, black 10%)' },
  maskBoth: { maskImage: 'linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%)' },
  grayout: { filter: 'grayscale(100%)' },
}));

const selectBetterPrice = (priceA: number, priceB: number) => {
  if (priceA === 0 || priceA === undefined) return priceB;
  if (priceB === 0 || priceB === undefined) return priceA;
  return Math.min(priceA, priceB);
};

export const EnsuranceProviderFilter = (props: { onChange?: (values: string[]) => void }) => {
  const offers = useAppSelector((state) => state.ensuranceList.list);

  const ensuraceProvider = useMemo(() => {
    return (offers || [])
      .filter((o) => !o.failed)
      .reduce((acc, offer) => {
        if (Object.hasOwn(acc, offer.filterkey))
          return {
            ...acc,
            [offer.filterkey]: {
              ...acc[offer.filterkey],
              count: acc[offer.filterkey].count + 1,
              maxRating: Math.max(acc[offer.filterkey].maxRating, offer?.badgeDetails?.percent || 0),
              minPrice: selectBetterPrice(acc[offer.filterkey].minPrice, offer.offer),
              allPrices: [...acc[offer.filterkey].allPrices, offer.offer],
            },
          };

        return {
          ...acc,
          [offer.filterkey]: {
            name: offer.insuranceDisplayName,
            logo: offer.logo_maybe_vector || offer.logo,
            count: 1,
            maxRating: offer?.badgeDetails?.percent || 0,
            minPrice: offer.offer,
            allPrices: [offer.offer],
          },
        };
      }, {});
  }, [offers]);

  const sortedKeys = useMemo(() => {
    const keys = Object.keys(ensuraceProvider);

    return keys.sort((a, b) => {
      const prop = 'minPrice';
      const aVal = !ensuraceProvider[a][prop] ? Number.MAX_SAFE_INTEGER : ensuraceProvider[a][prop];
      const bVal = !ensuraceProvider[b][prop] ? Number.MAX_SAFE_INTEGER : ensuraceProvider[b][prop];

      return aVal - bVal;
    });
  }, [ensuraceProvider]);

  const [selected, setSelected] = useState<string[]>([]);

  const { classes } = useChipStyles();

  useEffect(() => {
    if (!props.onChange) return;
    props.onChange(selected);
  }, [selected]);

  const viewport = useRef<HTMLDivElement>(null);

  const onWheel = useCallback(
    (e: WheelEvent) => {
      if ((viewport.current.scrollLeft, viewport.current.clientWidth === viewport.current.scrollWidth)) return;

      e.preventDefault();
      e.stopPropagation();

      viewport.current.scrollTo({
        behavior: 'auto',
        left: viewport.current.scrollLeft + Math.max(-80, Math.min(e.deltaY, 80)),
      });
    },
    [viewport]
  );

  const styles = useStyles();
  const [scrollClass, setScrollClass] = useState(null);

  useEffect(() => {
    const handler = (e: Event) => {
      const isRight =
        viewport.current.scrollLeft > 0 && !(viewport.current.scrollWidth <= viewport.current.clientWidth);
      const isLeft =
        Math.abs(viewport.current.scrollLeft + viewport.current.clientWidth - viewport.current.scrollWidth) >= 1 &&
        !(viewport.current.scrollWidth <= viewport.current.clientWidth);

      if (!isLeft && !isRight) setScrollClass('');
      else if (isLeft && isRight) setScrollClass(styles.classes.maskBoth);
      else if (isLeft) setScrollClass(styles.classes.maskLeft);
      else if (isRight) setScrollClass(styles.classes.maskRight);
    };
    viewport.current?.addEventListener('scroll', handler);
    return () => viewport.current?.removeEventListener('scroll', handler);
  }, []);

  if (sortedKeys.length === 0) return null;

  return (
    <WheelCapture onWheel={onWheel}>
      <ScrollArea
        viewportRef={viewport}
        className={scrollClass}>
        <Chip.Group
          noWrap
          value={selected}
          multiple
          onChange={setSelected}
          spacing={'xs'}
          pb={'sm'}
          style={{ whiteSpace: 'nowrap' }}>
          {sortedKeys.map((key) => (
            <Chip
              classNames={classes}
              className={selected.length === 0 || selected.includes(key) ? '' : styles.classes.grayout}
              value={key}
              radius={'sm'}
              key={key}>
              <Flex
                direction={'row'}
                align={'center'}
                h={'100%'}
                m={3}>
                <Box
                  component='img'
                  h={'2em'}
                  src={ensuraceProvider[key].logo}
                  alt={ensuraceProvider[key].name}
                />
                {!ensuraceProvider[key].minPrice ? null : (
                  <Text>Ab {numeral(ensuraceProvider[key].minPrice).format('0.00')}&nbsp;€</Text>
                )}
              </Flex>
            </Chip>
          ))}
        </Chip.Group>
      </ScrollArea>
    </WheelCapture>
  );
};
