import { getUrl } from '@/actions/data';
import { addUserDataPostFields } from '@/actions/data/sharedPostField';
import { changeUserInput } from '@/actions/form';
import { useAppDispatch, useOnAppReady, useOnRequiredFieldsLoaded } from '@/hooks';
import { AppStateGetterType } from '@/store';
import { useState } from 'react';

let isRun = false;

const RunRefererPing = () => {
  return async (dispatch: AppDispatch, getState: AppStateGetterType) => {
    
    if (isRun) return;
    isRun = true;

    const data = new FormData();

    const request = JSON.parse(JSON.stringify(getState().userData));
    addUserDataPostFields(getState(), request, data);

    fetch(getUrl('/api/ens/v1/inclusion/refererPing'), {
      method: 'POST',
      body: data,
    })
      .then((res) => res.json())
      .then((res: { discoveredRefererId: string | null }) => {
        // https://app.asana.com/0/1174272655772669/1206077412348660/f
        // Set a fallback voucher code if no referer is present

        if ((res.discoveredRefererId && res.discoveredRefererId !== 'null') || getState().userData.voucherCode !== '')
          return;

        if (!window?.ensOptions?.ensVoucherCodeNoRefererFallback) return;

        dispatch(changeUserInput('voucherCode', window.ensOptions.ensVoucherCodeNoRefererFallback));
      });
  };
};

const RefererPing = () => {
  const dispatch = useAppDispatch();
  const [isRun, setIsRun] = useState(false);

  useOnAppReady(async () => {
    if (isRun) return;
    setIsRun(true);

    dispatch(RunRefererPing());
  }, isRun);

  return null;
};

export default RefererPing;
