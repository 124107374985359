import React, { CSSProperties, useEffect, useMemo, useRef } from 'react';

export default ({ children = null, setIsSticky, offset = 0 }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setIsSticky) setIsSticky(false);
  }, []);

  useEffect(() => {
    if (!ref.current) return null;
    const options = {
      //root: document.querySelector('#scrollArea'),
      rootMargin: '0px',
      threshold: 0.1
    };

    const callback = (entries) => {
      if (!setIsSticky) return;
      setIsSticky(!entries?.[0]?.isIntersecting);
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref]);

  const style: CSSProperties = useMemo(() => ({ position: 'relative', top: `${offset}px` }), [offset]);

  return (
    <>
      <div
        style={style}
        ref={ref}
      />
      {children}
    </>
  );
};
