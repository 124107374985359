import React, { useCallback, useMemo } from 'react';
import { Accordion, Button, Container, createStyles, Group } from '@mantine/core';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { EnsPage, navigateTo } from '@/actions/progress';
import { type EnsProcessDefinitionDefault, type EnsProcessStep } from './EnsProcess';
import { useEnsProcessContext } from './DefaultEnsProcessContext';
import { useNextEvent, usePrevEvent } from './ProcessEventHandler';
import { ButtonLink } from '@unserkunde/enscompare-components';
import { FaArrowRight } from 'react-icons/fa';
import { EnsIcon } from '@/forms/shared/AvailalbeEnsIcons';
import { useBrowsersBack } from '@/features/BrowserNavigationHandler';
import { useVisibleStepProcesses } from './useVisibleStepProcesses';

const useAccordionStyles = createStyles((theme) => ({
  root: {},
  control: {
    backgroundColor: 'transparent',
    borderRadius: theme.radius[theme.defaultRadius],

    '&[disabled]': {
      cursor: 'default',
    },
    '&.active': {
      backgroundColor: theme.colors.gray[2],
      cursor: 'default',
      '> *[class*="Accordion-chevron"]': {
        display: 'none',
      },
    },
    '&:hover:not([disabled])': {
      backgroundColor: theme.colors.gray[2],
    },
  },
  item: {
    background: 'none !important',
    border: `1px solid ${theme.colors.gray[2]}`,
    marginTop: theme.spacing.xs,
    borderRadius: theme.radius[theme.defaultRadius],

    '&.active': {
      border: `1px solid ${theme.colors.gray[4]}`,
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.2)',
    },
  },

  chevron: {
    '&[data-rotate]': {
      transform: 'rotate(90deg)',
    },
  },
}));

export const ButtonRow = React.memo(
  (props: {
    disable?: boolean;
    onNavigate: (isNavigated: boolean) => any;
    bottomMode?: boolean;
    allFluentPages: string[];
    step: string;
    stepItem: EnsProcessStep;
  }) => {
    const dispatch = useAppDispatch();

    const FluentProgress = useEnsProcessContext() as EnsProcessDefinitionDefault;

    const onNext = useNextEvent(props.step);
    const onBack = usePrevEvent(props.step);

    const onNavigate = useCallback(() => {
      props.onNavigate(true);
      onNext();
      props.onNavigate(false);
    }, [props.onNavigate, onNext]);

    useBrowsersBack(onBack, props.bottomMode);

    if (props.stepItem.hideDefaultButtons) return null;

    return (
      <Container
        mt={props.bottomMode ? 'lg' : 'sm'}
        mb={props.bottomMode ? undefined : 'md'}>
        <Group
          align='center'
          position='apart'>
          <ButtonLink
            data-testid='btn-ens-prev'
            leftIcon={
              <EnsIcon
                icon='FaArrowLeft'
                useAppColor={true}
              />
            }
            onClick={onBack}
            color='primary'>
            Zurück
          </ButtonLink>
          <Button
            loading={props.disable}
            data-testid='btn-ens-next'
            color='secondary'
            onClick={onNavigate}>
            {props.stepItem.nextStepLabel || 'Weiter'}&nbsp;&nbsp;
            <FaArrowRight color='white' />
          </Button>
        </Group>
      </Container>
    );
  }
);

export const FluentRenderComponent = () => {
  const step = useAppSelector((state) => state.router) as EnsPage;
  const FluentProgress = useEnsProcessContext() as EnsProcessDefinitionDefault;
  const stepItem = FluentProgress.processSteps[step];
  const Component = stepItem?.component || (() => 'No component found');

  const visibleSteps = useVisibleStepProcesses();

  const FluentPages = useMemo(
    () =>
      visibleSteps.filter((pageKey: EnsPage) => FluentProgress.processSteps[pageKey].useFluentProgress) as EnsPage[],
    [FluentProgress, visibleSteps]
  );

  const { classes } = useAccordionStyles();
  const dispatch = useAppDispatch();

  const allFluentPages = FluentPages;

  const accordionCallback = useCallback((newStepKey) => {
    if (newStepKey === '__startpage') newStepKey = '';
    if ((!newStepKey && newStepKey !== '') || stepItem.fluentPreventBack) return;
    dispatch(navigateTo(newStepKey as EnsPage));
  }, []);

  const [navigationDisabled, setNavigationDisabled] = React.useState(false);

  if (!FluentPages.includes(step)) return <Component />;

  return (
    <Accordion
      classNames={classes}
      className={classes.root}
      defaultValue={step}
      onChange={accordionCallback}
      value={step || '__startpage'}
      variant='filled'>
      {allFluentPages.map((fluentKey: EnsPage) => (
        <Accordion.Item
          value={fluentKey || '__startpage'}
          key={fluentKey}
          className={fluentKey === step ? 'active' : ''}>
          <Accordion.Control
            disabled={
              FluentProgress.processSteps[fluentKey].fluentPreventDirectSelect ||
              stepItem.fluentPreventBack ||
              allFluentPages.indexOf(fluentKey) > allFluentPages.indexOf(step)
            }
            className={fluentKey === step ? 'active' : ''}>
            {FluentProgress.processSteps[fluentKey].title}
          </Accordion.Control>
          <Accordion.Panel>
            {step === fluentKey && (
              <>
                <ButtonRow
                  disable={navigationDisabled}
                  onNavigate={setNavigationDisabled}
                  allFluentPages={allFluentPages as string[]}
                  step={step}
                  stepItem={stepItem}
                />
                <Component />
                <ButtonRow
                  disable={navigationDisabled}
                  onNavigate={setNavigationDisabled}
                  bottomMode={true}
                  allFluentPages={allFluentPages as string[]}
                  step={step}
                  stepItem={stepItem}
                />
              </>
            )}
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};
