import queryString from 'query-string';
import requestOffers from '../data/requestOffers';
import { getUrl } from '../shared/getUrl';
import { EnsPage, ensuranceSelected } from '../progress';
import { getAntragsaenderungState } from '../../features/Antragsaenderung';
import { getOriginalWindowSearch } from '../shared/QueryParams';
import { onSaveUncompletedOrderConsent } from '@/reducer/uncompletedOrder';

declare let window: any;

export const loadpredefdata = (predef, selectedEns) => {
  return async (dispatch: AppDispatch) => {
    await dispatch({ type: 'PREDEF_LOADING' });

    const formData = new FormData();
    formData.append('predef', predef);
    if (getAntragsaenderungState()) {
      // @ts-ignore
      formData.append('antragsaenderung', getAntragsaenderungState());
    }

    if (selectedEns) formData.append('selectedEns', selectedEns);

    const result = await fetch(getUrl('/wp-admin/admin-post.php?action=load_predef_values'), {
      body: formData,
      method: 'post',
    })
      .then((o) => o.json())
      .catch((e) => {
        console.log(e);
      });

    if (result && !result.error) {
      // Das Frontend arbeitet noch mit dem Feld "Bikes", das Backend trennt die Felder schon.
      // Daher muss dies hier noch einmal übernommen werden.
      if (result.dogs && !result.bikes) {
        result.bikes = result.dogs;
      }
      if (result.insuranceObjects && !result.bikes) {
        result.bikes = result.insuranceObjects;
      }

      if (result.defaultObjectId && !result.defaultBikeId) {
        result.defaultBikeId = result.defaultObjectId;
      }
      if (!Object.keys(result.bikes).includes(result.defaultBikeId)) {
        result.defaultBikeId = Object.keys(result.bikes)[0] || null;
      }

      await dispatch({ type: 'PREFED_LOADED', data: result });

      if (!queryString.parse(getOriginalWindowSearch()).predef_no_referer) {
        window.ensRefererOverride = result.refererId;
      }

      if (result._predef_selectedEnsurance) {
        const overrideNavigationPage: EnsPage = result._predef_source === 'offer_uncomp' ? 'personalInfo' : null;

        await dispatch(ensuranceSelected(result._predef_selectedEnsurance, { overrideNavigationPage }));
        await dispatch(requestOffers(true));
      }

      if (result._predef_source === 'offer_uncomp') {
        await dispatch(onSaveUncompletedOrderConsent());
      }
    } else {
      console.log('Predef konnte nicht geladen werden: ' + result?.error);
    }

    await dispatch({ type: 'PREDEF_COMPLETED', success: !!result && !result.error });
  };
};
