import React, { useEffect } from 'react';
import { PageTracking } from './PageTracking';
import { trackers } from '@/actions/tracking/index';
import { useAppSelector } from '@/hooks';
import { TrackerType } from '@/actions/tracking/TrackerType';


export default function TrackingComponent () {
  const ensfields = useAppSelector((state) => state.ensfields);

  useEffect(() => {
    if (!ensfields.requiredFieldsLoaded) return;

    const initFbTracking = async () => {
      await Promise.all(
        trackers.map((tracker: TrackerType) => {
          if (!tracker.onInit) return null;
          return tracker.onInit(ensfields);
        })
      );
    };
    initFbTracking();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ensfields.requiredFieldsLoaded]);

  if (!ensfields.requiredFieldsLoaded) return null;

  return <PageTracking name='initApp' />;
}
