import React, { useEffect, useState } from 'react';
import { HookedTextInput } from './HookedInputs';
import { getQueryStrings } from '@/actions/shared/QueryParams';

const MitarbeiterkennungControl = () => {
  const [hideControl, setHideControl] = useState(false);

  useEffect(() => {
    if(getQueryStrings()?.hideMitarbeiterkennung || window.ensOptions?.hideMitarbeiterkennung){
      setHideControl(true);
    }
  })

  if(hideControl) return null;

  return (
    <div title={'Dies kannst du eintragen, wenn du den Rechner im Auftrag deines Arbeitgebers nutzt.'}>
      <HookedTextInput variant="unstyled" field={'employerTag'} transparent="true" placeholder={'Mitarbeiterkennung'} />
    </div>
  );
};

export default MitarbeiterkennungControl;
