import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { TitleLabel } from './Common';
import InstanceNaming, { useInstanceNaming } from './InstanceNaming';
import InstanceModeSwitch from './Switches/InstanceModeSwitch';

const Contactcard = () => {
  const contactMail = useInstanceNaming('contactEmail');
  const contactPhone = useInstanceNaming('contactPhone');
  const contactName = useInstanceNaming('contactName');

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
      <div
        style={{
          border: '1px solid black',
          borderRadius: '5px',
          maxWidth: '400px',
          marginTop: '20px',
          marginBottom: '30px',
          boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.25)'
        }}>
        <div style={{ padding: '15px' }}>
          <p>
            Ups, scheint so als möchte keiner dein <InstanceNaming fieldName="ensuranceObjectName" /> versichern.
          </p>
          <InstanceModeSwitch enable={'bike'}>
            <p>Ist es vielleicht gebraucht oder gewerblich angeschafft worden? </p>
          </InstanceModeSwitch>
          <p>Naja, vielleicht finden wir trotzdem eine Lösung für dich, kontaktiere mich hierfür einfach direkt.</p>
        </div>
        <hr />
        <Container style={{ paddingBottom: '10px' }}>
          <Row>
            <Col sm={12}>{contactName}</Col>
          </Row>
          <Row>
            <Col sm={6}>
              <TitleLabel>Email</TitleLabel>
            </Col>
            <Col sm={6}>
              <TitleLabel>
                <a href={'mailto:' + contactMail}>{contactMail}</a>
              </TitleLabel>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <TitleLabel>Telefon</TitleLabel>
            </Col>
            <Col sm={6}>
              <TitleLabel>
                <a href={'tel:' + contactPhone}>{contactPhone}</a>
              </TitleLabel>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Contactcard;
