import queryString from 'query-string';
import { getOriginalWindowSearch } from '@/actions/shared/QueryParams';

declare let window: any;

export const getPresetId = () => {
  const rawPresetId: string = queryString.parse(getOriginalWindowSearch()).presetId || window?.ensPreset?.presetId;
  return rawPresetId ? rawPresetId.trim() : rawPresetId;
};

window.getPresetId = getPresetId;

