import { reactRootElement } from '@/indexElements';


export const focusByClass = (className, focusField = true) => {
    const item = reactRootElement.querySelectorAll('.' + className).item(0);
    if (!item) {
      return;
    }
    item.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
    if (focusField) {
      //@ts-ignore
      item.focus();
    }
  };