import React, { useCallback } from 'react';
import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';
import { InputRow } from '../shared/Common';
import { getLabel } from '../shared/Labels';
import { HookedSelect, HookedTextInput } from '../shared/HookedInputs';
import { useAppDispatch, useInpectionUserData } from '../../hooks';
import { updateInspectionVoucherPrice } from '../../actions/data/inspectionVoucherActions';

const InspectionVoucherInputs = () => {
  /*  const dispatch = useAppDispatch();

    const onUpdatePrice = useCallback(() => {
      dispatch(updateInspectionVoucherPrice());
    }, []);
  */

  return (
    <>
      <InputRow inputSize={9} title={getLabel('iban', true)}>
        <HookedTextInput field={'iban'} />
      </InputRow>
      {/*
      <InputRow inputSize={9} title={getLabel("voucher", true)}>
        <HookedTextInput
          field={"voucher"}
          userDataHook={useInpectionUserData}
          onBlur={onUpdatePrice}
        />
      </InputRow>
      */}
    </>
  );
};

export default InspectionVoucherInputs;

const SelectionContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: baseline;
`;

export const InspectionVoucherAmount = () => {
  const dispatch = useAppDispatch();
  const onUpdatePrice = useCallback(() => {
    dispatch(updateInspectionVoucherPrice());
  }, []);

  return (
    <Row style={{ marginBottom: '25px', marginTop: '5px' }}>
      <Col>
        <SelectionContainer>
          <HookedSelect
            field={'amount'}
            compact
            values={[
              [1, '1'],
              [2, '2'],
              [3, '3'],
              [4, '4'],
              [5, '5']
            ]}
            defaultValue={1}
            userDataHook={useInpectionUserData}
            onDefaultSet={onUpdatePrice}
            onChange={onUpdatePrice}
          />
          &nbsp;&nbsp;Inspektionsgutscheine möchte ich bestellen.
        </SelectionContainer>
      </Col>
    </Row>
  );
};
