import requestEnsurance from '@/actions/data/requestEnsurance';
import { usePremiumserviceChecklist } from '@/forms/InspectionVoucher/InspectionVoucherOrderComponent';
import { GetIsDevMode } from '@/forms/shared/IsDevMode';
import { EnsPriceInfo } from '@/forms/shared/pricing/EnsPriceInfo';
import { useAppSelector, useAppDispatch } from '@/hooks';
import { useCustomProduct } from '@/reducer/customProducts';
import { CustomProductNames, CustomProduct, CustomProductPrice } from '@/reducer/customProducts/customProducts.types';
import { Card, Group, Box, Title, Spoiler, Stack, Select, LoadingOverlay, Text } from '@mantine/core';
import { Checklist, Price2 } from '@unserkunde/enscompare-components';
import numeral from 'numeral';
import React, { useCallback, useMemo } from 'react';
import { CustomProductImageDisplay } from './CustomProductImageDisplay';
import { GpsTrackerHeadpoints } from '@/forms/success/GpsSelectionCard';
import { useSortedProductKeys } from './useSortedProductKeys';

const CustomProductRow = (props: { productKey: CustomProductNames }) => {
  const customProduct = useAppSelector((state) => state.customProducs.serverProductSelection[props.productKey]);

  const productBaseInfo = useAppSelector(
    (state) => state.customProducs.custom_products[props.productKey]
  ) as CustomProduct<typeof props.productKey>;

  const [customProductInfo, setVariant, setAmount] = useCustomProduct(props.productKey);

  const dispatch = useAppDispatch();

  const setAmountCallback = useCallback(
    async (newValue: string) => {
      if (!customProduct) return;
      if (!customProduct.itemsToAdd) {
        const newValueNum = parseInt(newValue);
        if (newValueNum) await setAmount(newValueNum);
        else {
          await setAmount(0);
          await setVariant('none');
        }

        await dispatch(requestEnsurance());
      } else throw new Error('Not implemented incl custom products');
    },
    [customProduct, setAmount, setVariant]
  );

  const priceInfos = useMemo(
    () =>
      customProduct?.prices
        ?.filter((p: CustomProductPrice) => !p?.disablePriceDisplay)
        .map((p) => EnsPriceInfo.fromCustomProductPrice(p)),
    [customProduct]
  );

  const maxAmount = productBaseInfo?.maxAmount;
  const amounts = useMemo(() => {
    return Array.from({ length: (maxAmount || 5) + 1 }, (_, i) => i + 1).map((i) => (i - 1).toString());
  }, [maxAmount]);

  const psChecklist = usePremiumserviceChecklist(false);

  const DetailsElement = useMemo(() => {
    if (props.productKey === 'premiumservice' || props.productKey === 'gpsTracker') {
      return (
        <Checklist
          isOpen={false}
          /* @ts-ignore */
          items={props.productKey === 'premiumservice' ? psChecklist.items : GpsTrackerHeadpoints.items}
          preview={1000}
          labelClosed={undefined}
          labelOpen={undefined}
        />
      );
    } else if (props.productKey === 'sta_product1') {
      return (
        <Checklist
          isOpen={false}
          preview={1000}
          items={[
            {
              uuid: 1,
              type: 'positive',
              children: <b>Erstinspektionsgutschein im Wert von 70,-€</b>,
            },
            ...[
              'Überprüfung der Kürzungen und Ablehnungen',
              'Schadenmanagement & Direktabrechnung mit Werkstatt',
              'Werkstattsuche im Schadenfall',
              'Papierlose Bearbeitung inkl. digitalem Versicherungsordner',
              'Unterstützung bei der Ersatzteilversorgung',
              'Rückmeldung innerhalb von 24 Stunden werktags garantiert',
            ].map((text, i) => ({
              uuid: i + 2,
              type: 'positive',
              children: text,
            })),
          ]}
          labelClosed={undefined}
          labelOpen={undefined}
        />
      );
    } else if (props.productKey === 'sta_product2') {
      return (
        <Checklist
          preview={1000}
          isOpen={true}
          items={[
            {
              uuid: 1,
              type: 'positive',
              children: <b>Erstinspektionsgutschein im Wert von 70,-€</b>,
            },
          ]}
          labelClosed={undefined}
          labelOpen={undefined}
        />
      );
    } else if (props.productKey === 'cashbackVoucher') {
      return (
        <Text>
          Das Cashback wird zwei Monate nach Versicherungsbeginn, frühstens aber zum darauffolgenden 15. ausgezahlt,
          sofern der Erstbeitrag bezahlt und der Vertrag ungekündigt besteht und nicht widerrufen wurde.
        </Text>
      );
    }
    return null;
  }, [props.productKey, psChecklist]);

  return (
    <Card
      shadow='sm'
      p='lg'
      radius='md'
      style={{ overflow: 'visible' }}
      withBorder>
      <Group
        position='apart'
        align='flex-start'
        noWrap>
        <Group
          align='flex-start'
          noWrap>
          <CustomProductImageDisplay productInfo={productBaseInfo} />

          <Box>
            <Title order={6}>{customProduct?.displayName}</Title>
            {DetailsElement && (
              <Spoiler
                initialState={true}
                maxHeight={0}
                showLabel='Details ansehen'
                hideLabel='Details verbergen'>
                {DetailsElement}
              </Spoiler>
            )}
          </Box>
        </Group>

        <Stack
          spacing='xs'
          w='min-content'>
          <Select
            value={customProductInfo?.amount?.toString() || '0'}
            onChange={setAmountCallback}
            data={amounts}
            miw='5em'
          />

          <>
            {priceInfos?.map((priceInfo) => (
              <Price2>
                <Price2.Price
                  label={
                    <>
                      {priceInfo.getPeriodDisplay()}
                      {(customProduct?.amount || 0) >= 2 ? <>&nbsp;Je</> : ''}{' '}
                      {numeral(priceInfo.amount).format('0.00')} €
                    </>
                  }
                  size={'lg'}>
                  &nbsp;
                </Price2.Price>
              </Price2>
            ))}
          </>
        </Stack>
      </Group>
      {GetIsDevMode() && (
        <Spoiler
          maxHeight={0}
          hideLabel={'Verbergen'}
          showLabel={'Debug Info'}>
          Test
        </Spoiler>
      )}
    </Card>
  );
};

export const CustomProductsSelection = () => {
  const serverCustomProducts = useAppSelector((state) => state.customProducs.serverProductSelection) || {};
  const loading = useAppSelector((state) => state.checkout.loading);

  const sortedProductKeys = useSortedProductKeys(false);

  return (
    <>
      {sortedProductKeys.map((productKey: CustomProductNames, i: number) => (
        <Box
          key={productKey}
          mt={'lg'}>
          <CustomProductRow productKey={productKey} />
        </Box>
      ))}
      <LoadingOverlay visible={loading} />
    </>
  );
};
