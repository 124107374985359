import React, { useCallback, useEffect, useMemo } from 'react';
import { createStyles, Select } from '@mantine/core';
import { getPaymentPeriodsBase } from '../shared/Ensfeatures';
import { HookedSelect, SelectArrayEntry } from '../shared/HookedInputs';
import requestOffers from '@/actions/data/requestOffers';
import { useAppDispatch, useAppSelector, useOnInputFieldChange } from '@/hooks';
import { SwitchMindestvertragslaufzeit } from '../shared/Switches/custom/SwitchMindestvertragslaufzeit';
import { SwitchZahlweise } from '../shared/Switches/custom/SwitchZahlweise';

export const useFilterOptionStyles = createStyles(
  (theme: MantineEnsTheme, props?: { horizontalSegment?: boolean } | null) => ({
    listOptionsGrup: {
      border: '1px solid #e0e0e0',
      borderRadius: theme.radius.sm,
      backgroundColor: 'white',

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      [theme.fn.smallerThan('sm')]: {
        flexDirection: 'column',
      },
    },
    listOptionSegment: {
      padding: theme.spacing.md,
      flexGrow: 1,
      [theme.fn.largerThan('lg')]: {
        padding: theme.spacing.lg,
      },

      borderRight: '1px solid #e0e0e0',
      [theme.fn.smallerThan('sm')]: {
        borderRight: 'none',
        borderBottom: '1px solid #e0e0e0',
      },

      ['&:last-child']: {
        border: 'none',
      },
    },
    listOptionSegmentLabel: {
      display: 'inline-block',
      paddingBottom: theme.spacing.xs,
      fontSize: theme.fontSizes.xs,
      color: theme.colors.primary[9],
      ...theme.fn.fontStyles(),
      fontWeight: 600,
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',

      ...(props?.horizontalSegment
        ? {
            flexDirection: 'row',
            '&>*': {
              flexGrow: 1,
            },
          }
        : null),

      justifyContent: 'space-between',
      gap: '7px',
      ['label']: {
        fontSize: theme.fontSizes.xs,
      },
    },
  })
);

const mindestvertragslaufzeiten = [
  { label: '3 Jahre', value: '3' },
  { label: '1 Jahre', value: '1' },
];

const mindestvertragslaufzeitenOnly1Year = [{ label: '1 Jahre', value: '1' }];

export const OptionsGroup = ({ children }) => {
  const { classes } = useFilterOptionStyles(null);

  return <div className={classes.listOptionsGrup}>{children}</div>;
};

export const useVertragslaufzeiten = () => {
  const isGewerblicherProzess = useAppSelector(
    (state) => (state.userData.deviceModeOverride || state.devicemode.devkey) === 'gewerblich'
  );

  return isGewerblicherProzess ? mindestvertragslaufzeitenOnly1Year : mindestvertragslaufzeiten;
};

export const usePaymentPeriods = () => {
  const paymentPeriods: SelectArrayEntry[] = useMemo(
    () => getPaymentPeriodsBase().map((a) => a.reverse() as SelectArrayEntry),
    []
  );
  return paymentPeriods;
};

const EnsuranceListOptions = ({ sortOptions, sortBy, setSortBy }) => {
  const dispatch = useAppDispatch();

  const { classes } = useFilterOptionStyles({ horizontalSegment: true });

  const onReloadOffers = useCallback(() => dispatch(requestOffers(true, false, true)), []);
  const paymentPeriods = usePaymentPeriods();

  const sortByValues = useMemo(() => sortOptions?.map((so) => ({ label: so.label, value: so.key })), [sortOptions]);

  const vertragslaufzeiten = useVertragslaufzeiten();
  const currentVertragslaufzeit = useAppSelector((state) => state.userData.contractDurationYears);

  const changeVertragslaufzeit = useOnInputFieldChange('contractDurationYears');

  useEffect(() => {
    if (vertragslaufzeiten.find((v) => Number(v.value) === Number(currentVertragslaufzeit))) {
      return;
    }
    changeVertragslaufzeit(vertragslaufzeiten[0].value);
  }, [vertragslaufzeiten, currentVertragslaufzeit]);

  return (
    <div className={classes.listOptionsGrup}>
      <div className={classes.listOptionSegment}>
        <div className={classes.inputContainer}>
          <SwitchZahlweise>
            <HookedSelect
              label='Zahlweise'
              field={'paymentPeriod'}
              values={paymentPeriods}
              onChange={onReloadOffers}
              size='xs'
              disableWrap={true}
            />
          </SwitchZahlweise>

          <Select
            label='Sortierung'
            data={sortByValues}
            onChange={setSortBy}
            value={sortBy}
            size='xs'
          />

          <SwitchMindestvertragslaufzeit>
            <HookedSelect
              label='Mindestlaufzeit'
              helpText='Einige Versicherer gewähren Rabatte bei längeren Vertragslaufzeiten'
              field={'contractDurationYears'}
              values={vertragslaufzeiten}
              onChange={onReloadOffers}
              size='xs'
              disableWrap={true}
            />
          </SwitchMindestvertragslaufzeit>
        </div>
      </div>
    </div>
  );
};

export default EnsuranceListOptions;
