import { getUrl } from '../shared/getUrl';
import { getPageMode } from '../shared/getPageMode';

let _clientId: any = null;
const randInt = () => Math.floor(Math.random() * 1000000);
export function getClientId() {
  return (
    _clientId || (_clientId = '' + randInt() + '-' + randInt() + '-' + randInt() + '-' + randInt() + '-' + randInt())
  );
}

declare let window: any;
const Audit = async (message, actionId: string | number = 0, area = 'client') => {
  const auditBody = {
    pageMode: getPageMode(),
    location: window.ensOriginalCalcLocation || window.location.href,
    message
  };

  // Die ensClientId wird vom IFrameHost generiet
  const targetId = getClientId();

  return await fetch(getUrl(`/wp-json/ens/v1/audit/put/${area}/${targetId}/${actionId}`), {
    body: JSON.stringify(auditBody),
    method: 'post'
  });
};

export default Audit;
