import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Flex, Grid, Group, SimpleGrid, createStyles, useMantineTheme } from '@mantine/core';
import { compareModeActivate } from '../../actions/compare';
import { submitAngebotAnfordern } from '../../actions/progress';

import { changeUserInput } from '../../actions/form';
import ThemedSpan from '../shared/ThemedSpan';
import styles from './style.css?inline';
import { useEnsfieldsValue } from '@/features/Ensfields';

import { useIsXs } from '@unserkunde/enscompare-components/src/components/misc/hooks';

import coStyles from './CompareOverlay.scss?inline';

// @ts-ignore

import { addInlineStyle } from '@/indexElements';
import { useAppDispatch } from '@/hooks';

addInlineStyle(styles);
addInlineStyle(coStyles);

const useStyles = createStyles((theme, props: any) => {
  const mainColor = props.compareModeAvailable ? 'rgb(255,255,255)' : 'rgba(255,255,255,0.5)';

  return {
    container: {
      background: theme.colors.primary[8],
      top: 0,
      position: props.selectedItemsCount > 0 ? 'sticky' : 'relative',
      zIndex: '100',
    },
    vergleicherButton: {
      border: `1.5px solid ${mainColor}`,
      display: 'block',
      backgroundColor: 'transparent !important',
      color: mainColor,
      padding: '8px',
      fontWeight: 'bold',
      borderRadius: '0px',
      flexGrow: 1,
    },
    span: {
      fontWeight: 'bold',
      color: mainColor,
      flexGrow: 1,
    },
    inlineStyleButton: {
      border: `1.5px solid ${mainColor}`,
      background: props.hasError('email') ? 'rgba(255,0,0,0.7)' : 'transparent',
      color: mainColor,
      padding: '3px',
      marginBottom: '0',
      textAlign: 'center',
      outline: 'none',
    },
    mailInput: {
      borderTop: '0 !important',
      borderLeft: '0 !important',
      borderRight: '0 !important',
      overflow: 'hidden',
      flexGrow: 1,
    },
  };
});

const CompareOverlay = ({ compareModeAvailable, selectedItemsCount, hasError, userData, onChangeInput }) => {
  const dispatch = useAppDispatch();

  const onCompareModeActivate = useCallback(() => dispatch(compareModeActivate(true)), []);
  const onAngebotanfordern = useCallback(() => dispatch(submitAngebotAnfordern('default')), []);
  const onAngebotLink = useCallback(() => dispatch(submitAngebotAnfordern('link')), []);

  const compareTariffLimit = useEnsfieldsValue('compareTariffLimit', 3);

  const isXs = useIsXs();

  const { classes } = useStyles({ compareModeAvailable, hasError, selectedItemsCount });

  return (
    <SimpleGrid
      cols={isXs ? 1 : 2}
      className={(compareModeAvailable ? 'active' : '') + ' ensCompareOverlayBox ' + classes.container}>
      <Group>
        <ThemedSpan className={classes.span}>
          {selectedItemsCount} von {compareTariffLimit} gewählt
        </ThemedSpan>

        <button
          className={classes.vergleicherButton}
          onClick={onCompareModeActivate}
          disabled={!compareModeAvailable}>
          Jetzt vergleichen
        </button>
      </Group>

      <Flex
        wrap={'nowrap'}
        gap={'xs'}>
        <input
          className={`olInput ${classes.inlineStyleButton} ${classes.mailInput}`}
          type='text'
          placeholder={'E-Mail Adresse'}
          onChange={onChangeInput('email')}
          value={userData.email}
          disabled={!compareModeAvailable}
        />
        <button
          className={classes.vergleicherButton}
          onClick={onAngebotanfordern}
          disabled={!compareModeAvailable}>
          Angebot&nbsp;anfordern
        </button>

        {window.ensOptions?.allowOfferLinkRequest && (
          <button
            className={classes.vergleicherButton}
            onClick={onAngebotLink}
            disabled={!compareModeAvailable}>
            Angebotlink&nbsp;anfordern
          </button>
        )}
      </Flex>
    </SimpleGrid>
  );
};

const mapStateToProps = (state) => {
  return {
    compareModeAvailable: state.ensCompare.compareModeAvailable,
    selectedItemsCount: state.ensCompare.selectedNames.length,
    userData: state.userData,
    hasError: (value) => state.validation.errors.includes(value),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeInput:
      (field, selector = (o) => o.target.value) =>
      (value) =>
        dispatch(changeUserInput(field, selector(value))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareOverlay);
