import { trackByName } from '@/actions/tracking';
import { Box, Button, Group, Stack, TextInput } from '@mantine/core';
import React, { useCallback, useState } from 'react';

export const DevmodeTracking = () => {
  const [name, setName] = useState('');

  const track = useCallback(() => {
    trackByName(name);
    alert('Event gesendet');
  }, [name]);

  const knownEvents = ['nav_order_success'];

  return (
    <Stack mt={'md'}>
      <Group align='end'>
        <TextInput
          label='Events testen'
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <Button onClick={track}>Jetzt tracken</Button>
      </Group>
      <Group>
        {knownEvents.map((event) => (
          <Button
            variant='outline'
            size='xs'
            key={event}
            onClick={() => setName(event)}>
            {event}
          </Button>
        ))}
      </Group>
    </Stack>
  );
};
