export const getPaymentPeriodsBase = (skipMonthly = false): [string, string][] => {
  return [
    ['Jährlich', '1'],
    ['Halbjährlich', '2'],
    ['Vierteljährlich', '4'],
    ...((skipMonthly ? [] : [['Monatlich', '12']]) as [string, string][])
  ];
};

export const getFamilienraederValues = (): [string, string][] => {
  return [
    ['Nein', 'false'],
    ['Bis 1000€', '1000'],
    ['Bis 3000€', '3000']
  ];
};

export const getLaufzeitOptions = (offer): [string, string][] => {
  let defaultValue: [string, string][] = [
    ['Drei Jahre', '3'],
    ['Ein Jahr', '1']
  ];

  if (window.ensPresetData?.excludedDurationYears) {
    const excludedYears = window.ensPresetData.excludedDurationYears.split(',');
    defaultValue = defaultValue.filter((value) => !excludedYears.includes(value[1]));
  }

  if (!offer || !offer.options) {
    return defaultValue;
  }

  if (offer.options.includes('laufzeit_135')) {
    return [
      ['Ein Jahr', '1'],
      ['Drei Jahre', '3'],
      ['Fünf Jahre', '5']
    ];
  }

  return defaultValue;
};

export const getSortOfferFunc = () => {
  return (a, b) => {
    if (a.period === 'once' || b.period === 'once') {
      if (a.period !== b.period) {
        return a.period === 'once' ? 1 : -1;
      }
    }

    const aPeri = parseFloat(a.period) || 1;
    const bPeri = parseFloat(b.period) || 1;

    return (a.offer || a.offerMin || 0) * aPeri - (b.offer || b.offerMin || 0) * bPeri;
  };
};

export const changePos = (arr, selector, position) => {
  let index = -1;

  arr.forEach((e, i) => {
    if (selector(e)) {
      index = i;
    }
  });

  if (index === -1) {
    return arr;
  }

  const newArr = [...arr.filter((_, i) => i !== index)];
  newArr.splice(position, 0, arr[index]);

  return newArr;
};
