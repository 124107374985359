import { getQueryStrings } from '@/actions/shared/QueryParams';

declare let window: any;

let isPremiumserviceProcess: boolean | null = null;
export const IsPremiumserviceProcess = (): boolean => {
  if (isPremiumserviceProcess === null) {
    const queryStrings = getQueryStrings();
    isPremiumserviceProcess =
      Object.keys(queryStrings).includes('premiumserviceProcess') || window.ensOptions?.premiumserviceProcess;
  }
  return isPremiumserviceProcess;
};
