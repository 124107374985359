import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, Button, DefaultProps, Flex, Grid, LoadingOverlay, Stack, UnstyledButton } from '@mantine/core';
import { useIsXs, useIsMd } from '@unserkunde/enscompare-components/src/components/misc/hooks';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { useCounter } from '@mantine/hooks';
import DynamicEntryQuestion from '../shared/DynamicEntryQuestion';
import { InstanceNamingObjectInsuranceName, useInsuranceObjectName } from '../shared/InstanceNaming';
import { HookedDevicemodeSelect, HookedSelect } from '../shared/HookedInputs';
import { selectorIsLoading, useAppDispatch, useAppSelector, useDynamicFields } from '@/hooks';
import requestOffers from '@/actions/data/requestOffers';

import { EntryQuestionPosition } from '@/actions/types/DynamicEntryQuestionType';
import { AutoloadStandalone, useAutoloadEnsurances, useIsStandaloneMode } from '@/features/AutoloadEns';
import { EntryQuestionSaver } from '@/features/EntryQuestionSaver';
import {
  RecalculateStandanloneCompareTableId,
  SelectAutoloadStandaloneComapreTableId,
  getStandaloneCompareMapping,
} from '@/features/AutoloadStandaloneFeature';
import { changeUserInput } from '@/actions/form';
import { EnsAgbControl } from '../OverviewControls/EnsAgbControl';
import { GetIsDevMode } from '../shared/IsDevMode';
import { addSecondBike } from '@/actions/progress';
import { SwitchMindestvertragslaufzeit } from '../shared/Switches/custom/SwitchMindestvertragslaufzeit';
import { usePaymentPeriods, useVertragslaufzeiten } from '../ensuranceList/EnsuranceListOptions';
import { SwitchZahlweise } from '../shared/Switches/custom/SwitchZahlweise';
import { getQueryStrings } from '@/actions/shared/QueryParams';
import { useShowEntryQuestions } from './useShowEntryQuestions';

const useInputFields = (objectId, rows, fieldPosition: EntryQuestionPosition) => {
  const entryQuestions = useDynamicFields(fieldPosition);

  return useMemo(() => {
    return entryQuestions.map((eo, i) => {
      return (
        <DynamicEntryQuestion
          useFullHeight={true}
          autoFocus={false}
          key={i + '-' + eo.name + '-' + objectId}
          wrapper={Grid.Col}
          wrapperProps={{ span: eo.fieldType === 'header' ? 4 : 1 }}
          disableWrap={true}
          bikeId={objectId}
          setting={eo}
          nostrech={0}
        />
      );
    });
  }, [entryQuestions, entryQuestions, objectId, rows]);
};

const StandaloneDeviceModeSelect = () => {
  const allTables = useAppSelector((state) => state.ensfields.compareTables);
  const selectedDeviceOverride = useAppSelector((state) => state.userData.deviceModeOverride);

  const presetIdData = useAppSelector((state) => state.options.presetIdData);
  const mapping = getStandaloneCompareMapping({ options: { presetIdData } });

  const mappedCompareTableIds = useMemo(() => mapping.map((m) => m.compareTableId), [mapping]);

  const availableSelection = useMemo(
    () =>
      Object.values(allTables || {})
        .filter((t) => mappedCompareTableIds.includes(t.id))
        ?.map((t) => t.devicemodes)
        .flat() || [],
    [allTables, mappedCompareTableIds]
  );

  // Set to default if not in list
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!availableSelection.length || availableSelection.includes(selectedDeviceOverride)) return;

    dispatch(changeUserInput('deviceModeOverride', availableSelection[0]));
  }, [availableSelection]);

  if (availableSelection.length < 2 && !GetIsDevMode()) return null;

  return (
    <Grid.Col span={1}>
      <HookedDevicemodeSelect
        useFullHeight={true}
        showOptions={availableSelection || undefined}
        field='deviceModeOverride'
        label='Risikoart'
        disableWrap={true}
      />
    </Grid.Col>
  );
};

const StandaloneCompareSourceSelect = () => {
  const dispatch = useAppDispatch();
  const knownCompareTables = useAppSelector((state) => state.ensfields.compareTables);

  const selectedDeviceOverride = useAppSelector((state) => state.userData.deviceModeOverride);

  const presetIdData = useAppSelector((state) => state.options.presetIdData);

  const selectData = useMemo(() => {
    return (
      knownCompareTables &&
      getStandaloneCompareMapping({ options: { presetIdData: presetIdData } })
        .filter(({ compareTableId }) => {
          return knownCompareTables[compareTableId].devicemodes.includes(selectedDeviceOverride);
        })
        .map((m) => ({
          value: m.compareTableId,
          label: knownCompareTables[m.compareTableId].displayName,
        }))
    );
  }, [selectedDeviceOverride, knownCompareTables, presetIdData]);

  const onReloadCompare = useCallback((compareTableId) => {
    dispatch(SelectAutoloadStandaloneComapreTableId(compareTableId));
  }, []);

  const selectedTableId = useAppSelector((state) => state.userData.__standaloneCompareTableId);
  useEffect(() => {
    if ((selectData || []).length === 0 || selectData.find((d) => d.value === selectedTableId)) return;

    dispatch(changeUserInput('__standaloneCompareTableId', selectData[0]?.value));
    onReloadCompare(selectData[0]?.value);
  }, [selectData, selectedTableId]);

  if (selectData?.length < 2 && !GetIsDevMode()) return null;

  return (
    <Grid.Col span={1}>
      <HookedSelect
        useFullHeight={true}
        field='__standaloneCompareTableId'
        onChange={onReloadCompare}
        label='Vergleich'
        values={selectData || []}
        disableWrap={true}
      />
    </Grid.Col>
  );
};

const SecondDeviceButton = (props: { onClick?: () => void }) => {
  const dispatch = useAppDispatch();
  const onAddSecondObject = useCallback(() => {
    dispatch(
      addSecondBike({
        skipNavigation: true,
        successCallback: () => {
          if (props.onClick) props.onClick();
        },
      })
    );
  }, [props.onClick]);

  return (
    <Button
      onClick={onAddSecondObject}
      compact
      variant='outline'>
      2. <InstanceNamingObjectInsuranceName /> hinzufügen
    </Button>
  );
};

const RemoveSecondDeviceButton = (props: { onClick?: () => void }) => {
  const dispatch = useAppDispatch();
  const onRemoveObject = useCallback(() => {
    dispatch({ type: 'REMOVE_BIKE' });
    if (props.onClick) props.onClick();
  }, []);

  return (
    <Button
      onClick={onRemoveObject}
      compact
      variant='outline'>
      2. <InstanceNamingObjectInsuranceName /> entfernen
    </Button>
  );
};

const useEntryQuestionControls = (rows, selectedOjectId = null) => {
  const objectFieldsTop = useInputFields(selectedOjectId, rows, 'objectEntryQuestionsTop');
  const objectFields = useInputFields(selectedOjectId, rows, 'objectEntryQuestions');

  const antragFieldsTop = useInputFields(null, rows, 'entryQuestionsTop');
  const antragFields = useInputFields(null, rows, 'entryQuestions');

  return useMemo(
    () => [...objectFieldsTop, ...antragFieldsTop, ...objectFields, ...antragFields],
    [antragFieldsTop, antragFields, objectFieldsTop, objectFields]
  );
};

const useOnRecalculate = () => {
  const dispatch = useAppDispatch();
  const publicCalcUrl = useAppSelector((state) => state.ensfields.ens_public_link_to_calculator);

  const autoloadMode = useAutoloadEnsurances();

  const entryQuestionShowed = useShowEntryQuestions();

  return useCallback(() => {
    if (autoloadMode !== 'compare_standalone')
      dispatch(
        requestOffers(true, true, true, {
          // Wenn die Eingangsfragen angezeigt werden, können sie auch geändert werden.
          // Daher muss es neu geprüft werden
          skipBasicValidation: entryQuestionShowed ? false : true,
          skipResetCompareMode: true,
          selectedNames: !getQueryStrings().ensCompareSelectedEns
            ? undefined
            : getQueryStrings().ensCompareSelectedEns.split(','),
        })
      );
    else {
      dispatch(RecalculateStandanloneCompareTableId());
    }
  }, [publicCalcUrl, autoloadMode, entryQuestionShowed]);
};

const useSizes = () => {
  return { isXs: useIsXs(), isMd: useIsMd() };
};

const RecalculateButtonLabel = 'Neu berechnen';

const relative: DefaultProps['style'] = { position: 'relative' };

const Vertragsfilter = () => {
  const vertragslaufzeiten = useVertragslaufzeiten();
  const paymentPeriods = usePaymentPeriods();

  return (
    <>
      <SwitchMindestvertragslaufzeit>
        <Grid.Col span={1}>
          <HookedSelect
            label='Mindestlaufzeit'
            helpText='Einige Versicherer gewähren Rabatte bei längeren Vertragslaufzeiten'
            field={'contractDurationYears'}
            values={vertragslaufzeiten}
            disableWrap={true}
            useFullHeight={true}
          />
        </Grid.Col>
      </SwitchMindestvertragslaufzeit>
      <SwitchZahlweise>
        <Grid.Col span={1}>
          <HookedSelect
            label='Zahlweise'
            field={'paymentPeriod'}
            values={paymentPeriods}
            disableWrap={true}
            useFullHeight={true}
          />
        </Grid.Col>
      </SwitchZahlweise>
    </>
  );
};

const CompareHorizontalInputFields = () => {
  const onRecalculate = useOnRecalculate();

  const loading = useAppSelector((state) => selectorIsLoading(state, { includeEnsuranceListLoading: true }));

  const { isXs, isMd } = useSizes();

  const rows = !isMd ? 3 : isXs ? 1 : 2;

  const objects = useAppSelector((state) => state.userData.bikes);
  const objectIds = useMemo(() => Object.keys(objects), [objects]);

  const isStandalone = useIsStandaloneMode();
  const [selectedObjectIdIndex, handleSelectedObjectIdIndex] = useCounter(0, {
    min: 0,
    max: !isStandalone ? objectIds.length - 1 : Number.MAX_SAFE_INTEGER,
  });

  const setNextObjectId = useCallback(
    () => handleSelectedObjectIdIndex.set((selectedObjectIdIndex + 1) % objectIds.length),
    [selectedObjectIdIndex]
  );

  const allFields = useEntryQuestionControls(rows, objectIds[selectedObjectIdIndex]);

  return (
    <Box
      style={relative}
      id='ens_compare_table_input_fields'>
      <Stack spacing='sm'>
        <Grid columns={rows}>
          <AutoloadStandalone>
            <StandaloneDeviceModeSelect />
            <StandaloneCompareSourceSelect />
          </AutoloadStandalone>
          <Vertragsfilter />
          {allFields}
        </Grid>
        <Flex
          justify={'flex-end'}
          mt='sm'>
          <AutoloadStandalone>
            <EnsAgbControl
              ta='right'
              buttonLabel={RecalculateButtonLabel}
            />
          </AutoloadStandalone>
        </Flex>
        <Flex
          justify={'space-between'}
          align='center'
          mb='lg'>
          <AutoloadStandalone>
            {objectIds.length === 1 && <SecondDeviceButton onClick={handleSelectedObjectIdIndex.increment} />}
            {objectIds.length > 1 && <RemoveSecondDeviceButton onClick={handleSelectedObjectIdIndex.reset} />}
          </AutoloadStandalone>

          <AutoloadStandalone hide>
            {objectIds.length > 1 && (
              <Button.Group>
                <Button
                  compact
                  disabled={selectedObjectIdIndex === 0}
                  onClick={handleSelectedObjectIdIndex.decrement}
                  variant='subtle'>
                  <AiOutlineLeft color='black' />
                </Button>
                <UnstyledButton
                  onClick={setNextObjectId}
                  px={'xs'}>
                  <InstanceNamingObjectInsuranceName /> {selectedObjectIdIndex + 1}
                </UnstyledButton>
                <Button
                  compact
                  disabled={selectedObjectIdIndex === objectIds.length - 1}
                  onClick={handleSelectedObjectIdIndex.increment}
                  variant='subtle'>
                  <AiOutlineRight color='black' />
                </Button>
              </Button.Group>
            )}
          </AutoloadStandalone>
          <Button
            compact
            onClick={onRecalculate}>
            {RecalculateButtonLabel}
          </Button>
        </Flex>
        {selectedObjectIdIndex === 0 && <EntryQuestionSaver />}
      </Stack>
      <LoadingOverlay visible={loading} />
    </Box>
  );
};

export default CompareHorizontalInputFields;
