import React from 'react';
import { connect } from 'react-redux';
import ThemedSpan from '../shared/ThemedSpan';
import VoucherBox from './VoucherBox';
import { Box } from '@mantine/core';

const innerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'flex-end',
  padding: '.5em 0',
  flexWrap: 'wrap',
};

const outerStyle = { margin: 0, minHeight: '6em' };

const VoucherInfoRow = ({ show, code, eurAmount = null }) => {
  if (!show) {
    return null;
  }

  return (
    <Box style={outerStyle}>
      <Box style={innerStyle}>
        <VoucherBox patternLeft>
          <ThemedSpan
            style={{
              color: '#fff',
              padding: '0 20px 0 10px',
            }}>
            Gutschein <b>"{code}"</b> {eurAmount && <>im Wert von {eurAmount} </>}eingelöst{' '}
          </ThemedSpan>
        </VoucherBox>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  const unit = state.voucher?.voucher?.amount?.unit;

  return {
    show: state.voucher.valid,
    unit,
    code: state.voucher?.voucher?.code,
  };
};

export default connect(mapStateToProps)(VoucherInfoRow);
