import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MdDoneAll } from 'react-icons/md';
import { Box, Flex } from '@mantine/core';
import { PureHtml, ToggleButton } from '../shared/style/components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import IsDevMode from '../shared/IsDevMode';
import ThemedSpan from '../shared/ThemedSpan';
import { OptionsGroup } from './EnsuranceListOptions';
import { toggleFilterProposal } from '@/actions/customFilter/toggleFilterProposal';
import EnsLoadingIndicator from 'forms/shared/loading/EnsLoadingIndicator';

export const FilterProposalToggleButton = ({ checked, ...props }) => {
  const ToggleButtonContent = useMemo(
    () => (
      <>
        Unsere Empfehlung{' '}
        {checked ? (
          <>
            <MdDoneAll color='white' />
          </>
        ) : null}
      </>
    ),
    [checked]
  );

  return (
    <ToggleButton
      {...props}
      checked={checked}>
      {ToggleButtonContent}
    </ToggleButton>
  );
};

const CustomFilterProposal = () => {
  const dispatch = useAppDispatch();

  const filterApplied = useAppSelector((state) => state.customFilter.filterProposalData.filterApplied);
  const proposalText = useAppSelector((state) => state.customFilter.filterProposalData.proposalText);
  const proposalData = useAppSelector((state) => state.customFilter.filterProposalData);

  const onApplyFilterProposal = useCallback(() => dispatch(toggleFilterProposal()), [filterApplied]);

  const [logoImageSource, setLogoImageSource] = useState<string | null>(null);
  const instanceMode = useAppSelector((state) => state.ensfields.ens_instance_mode);
  useEffect(() => {
    // @ts-ignore
    if (instanceMode === 'animal') import('./dogimage.jpg').then((e) => setLogoImageSource(e.default));
    else setLogoImageSource('https://ebikeversicherungen.net/wp-content/uploads/2023/01/FS-Bildmarke-Rot-RGB.png');
  }, []);

  if (!proposalText) return null;

  return (
    <OptionsGroup>
      <Box>
        <Flex
          p='md'
          gap={'xl'}>
          <div>
            {!logoImageSource ? (
              <EnsLoadingIndicator />
            ) : (
              <img
                src={logoImageSource}
                style={{ height: 'auto', maxWidth: '4em' }}
              />
            )}
          </div>
          <div>
            <ThemedSpan>
              <PureHtml content={proposalText} />
            </ThemedSpan>
            <FilterProposalToggleButton
              onClick={onApplyFilterProposal}
              checked={filterApplied}
            />
          </div>
        </Flex>

        <IsDevMode>
          <Box p='sm'>{JSON.stringify(proposalData)}</Box>
        </IsDevMode>
      </Box>
    </OptionsGroup>
  );
};

export default CustomFilterProposal;
