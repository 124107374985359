//import './wdyr';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createEmotionCache } from '@mantine/core';
import { StyleSheetManager } from 'styled-components';

import App from './App';

import './features/NumeralSetup';
import { addInlineStyle, emotionCacheRoot, reactRootElement, stylesRoot } from './indexElements';

import './ViteDynamicContentLoader';

// @ts-ignore
import styles from './styles/index.scss?inline';
// @ts-ignore
import globalStyles from './styles/globals.scss?inline';
import { resetStore } from './store';
import { disableEnsFieldPreload } from './actions/features/ensfields';

addInlineStyle(styles);
addInlineStyle(globalStyles);

const domRootId = window.ensDOMRoot || 'ensurance_view_root';
const domRoot = document.getElementById(domRootId);

const shadowDom = domRoot.attachShadow({ mode: 'open' });

shadowDom.appendChild(stylesRoot);
shadowDom.appendChild(emotionCacheRoot);
shadowDom.appendChild(reactRootElement);

const emotionCache = createEmotionCache({
  container: emotionCacheRoot,
  key: 's',
});

let root = createRoot(reactRootElement);

const EnsMain = () => {
  return (
    <StrictMode>
      <StyleSheetManager target={stylesRoot}>
        <App
          mantineProviderProps={{
            defaultProps: { Portal: { target: shadowDom } },
            emotionCache: emotionCache,
          }}
        />
      </StyleSheetManager>
    </StrictMode>
  );
};

root.render(<EnsMain />);

window.ensRerenderWithApiSource = (apiSourceUrl: string) => {
  root.unmount();

  window.ensOverrideHostUrl = apiSourceUrl;
  disableEnsFieldPreload();
  resetStore();

  root = createRoot(reactRootElement);
  root.render(<EnsMain />);
};

if (window.ensRootClass) {
  window.ensRootClass.split(' ').forEach((cl) => reactRootElement.classList.add(cl));
}
