import { Card, Code, Spoiler, Table, Title, Text } from '@mantine/core';
import React, { useEffect, useMemo } from 'react';
import { GetPredef } from './Predef';
import { useAppDispatch, useAppSelector } from '@/hooks';
import requestEnsurance from '@/actions/data/requestEnsurance';
import { getHostUrl } from '@/ViteDynamicContentLoader';
import { useContainerSize } from '@/forms/shared/EnsContainerObserver';
import { DevmodeTracking } from './DevmodeDisplay.Tracking';
import { getEntryQuestionsStorageKey, loadFromLocalStorage, useSaverIsActive } from './EntryQuestionSaver';

import {
  getFilterValuesSavingKey,
  hasSavedFilterValues as getHasSavedFilterValues,
} from '@/actions/customFilter/customFilterSaver';
import { useDispatch } from 'react-redux';

type HistoryItem = {
  timestamp: number;
  value: string | number;
};

const relativeStartTime = Date.now();

const useHistory = (val: string | number) => {
  const [value, setValue] = React.useState<string | number>(null);
  const [history, setHistory] = React.useState<HistoryItem[]>([]);

  useEffect(() => {
    if (val !== value) {
      setValue(val);
      setHistory([...history, { timestamp: Date.now(), value: val }]);
    }
  }, [val]);

  return history;
};

const HistoryDisplay = ({ history }: { history: HistoryItem[] }) => {
  return (
    <div>
      {history.map((item, i) => {
        if (i === 0 && !item.value) return null;
        return (
          <div key={i}>
            {item.value} ({(item.timestamp - relativeStartTime) / 1000})
          </div>
        );
      })}
    </div>
  );
};

const useShowTrackingDebug = () => {
  return useMemo(() => {
    return window.location.search.includes('showTrackingDebug');
  }, []);
};

const EntryQuestionRows = () => {
  const presetIdData = useAppSelector((state) => state.options.presetIdData);
  const presetName = useAppSelector((state) => state.userData.presetName);
  const storedEntryQuestions = loadFromLocalStorage(presetName, { options: { presetIdData: presetIdData } });

  const json = useMemo(() => JSON.stringify(storedEntryQuestions, null, 2), [storedEntryQuestions]);

  const hasLoadedEntryQuestions = useAppSelector((state) => state.userData.___savedEntryQuestionLoaded);
  const hasSavedFilterValues = useAppSelector(getHasSavedFilterValues);

  const isActive = useSaverIsActive();

  const [entryQuestionsFilterKey, setEntryQuestionsFilterKey] = React.useState<string | null>(null);
  const [filterKey, setFilterKey] = React.useState<string | null>(null);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch((_: any, getState: RootStateGetter) => {
      const state = getState();
      setFilterKey(getFilterValuesSavingKey(state, ''));
      setEntryQuestionsFilterKey(getEntryQuestionsStorageKey(state.userData.presetName, state));
    });
  });

  return (
    <tr>
      <td>Gespeicherte Eingangsfragen</td>
      <td>
        <Text>Saver: {isActive ? 'Aktiv' : 'Nicht aktiv'}</Text>
        <Text>
          Eingangsfragen: {hasLoadedEntryQuestions ? 'Vorhanden' : 'Nicht vorhanden'} (Key: {entryQuestionsFilterKey})
        </Text>
        <Text>
          Filterwerte: {hasSavedFilterValues ? 'Vorhanden' : 'Nicht vorhanden'} (Key: {filterKey})
        </Text>
        <Spoiler
          maxHeight={0}
          hideLabel={''}
          showLabel={''}>
          <Code children={json} />
        </Spoiler>
      </td>
    </tr>
  );
};

export default function DevmodeDisplay() {
  const showTrackingDebug = useShowTrackingDebug();

  const presetIdState = useAppSelector((state) => state.options.presetIdState);
  const presetIdStateHistory = useHistory(presetIdState);

  const predefState = useAppSelector((state) => state.options.predefState);
  const predefStateHistory = useHistory(predefState);

  const devicemode = useAppSelector(
    (state) => (state.userData.deviceModeOverride || state.devicemode.devkey) + ' ' + state.userData?.insuranceType
  );
  const devicemodeHistory = useHistory(devicemode);

  const compareTableId = useAppSelector((state) => state.ensCompare.tableId);
  const compareTableIdHistory = useHistory(compareTableId);

  const hostUrl = getHostUrl();
  const hostUrlHistory = useHistory(hostUrl);

  const containerSize = useContainerSize();
  const containerSizeHistory = useHistory(containerSize);

  return (
    <Card
      withBorder
      bg={'transparent'}>
      <Title order={4}>Debug Info</Title>
      <Table
        striped
        withBorder
        withColumnBorders>
        <thead>
          <tr>
            <th>Status</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Host Url</td>
            <td>
              <HistoryDisplay history={hostUrlHistory} />
            </td>
          </tr>
          <tr>
            <td>PresetId State</td>
            <td>
              <HistoryDisplay history={presetIdStateHistory} />
            </td>
          </tr>
          <tr>
            <td>Predef State</td>
            <td>
              <HistoryDisplay history={predefStateHistory} />
            </td>
          </tr>
          <tr>
            <td>Predef</td>
            <td>{GetPredef()}</td>
          </tr>
          <tr>
            <td>Devicemode</td>
            <td>
              <HistoryDisplay history={devicemodeHistory} />
            </td>
          </tr>
          <tr>
            <td>Compare Table Id</td>
            <td>
              <HistoryDisplay history={compareTableIdHistory} />
            </td>
          </tr>
          <tr>
            <td>Container Size</td>
            <td>
              <HistoryDisplay history={containerSizeHistory} />
            </td>
          </tr>
          <EntryQuestionRows />
        </tbody>
      </Table>

      {showTrackingDebug && <DevmodeTracking />}
    </Card>
  );
}
