import { Box } from '@mantine/core';
import React, { useMemo } from 'react';

export default function ThemedSpan({ children, size = 'sm', ...rest }) {
  const sx = useMemo(() => {
    return (theme: MantineEnsTheme) => ({
      ...theme.fn.fontStyles(),
      display: 'inline',
      fontSize: theme.fontSizes[size]
    });
  }, [size]);

  return (
    <Box
      sx={sx}
      {...rest}>
      {children}
    </Box>
  );
}
