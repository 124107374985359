import { getQueryStrings } from '../shared/QueryParams';
import hotjar from './hotjar';
import gtm from './gtm';
import fbevents from './fbevents';
import { consoleTracker } from './console';
import { TrackerType } from './TrackerType';
import { getPresetId } from '@/actions/shared/getPresetId';
import { MicrosoftAdsTracker } from './MicrosoftAds';

// Siehe https://app.asana.com/0/1174272655772669/1203795911406277/f
const noExternalTrackingPresetIds = ['64a2bab8287d65.22704576', '64f88e4726fe25.88397171'];

export const isExternTrackingEnabled = () => {
  return !noExternalTrackingPresetIds.includes(getPresetId());
};

export let trackers = [
  ...(!isExternTrackingEnabled() ? [] : [hotjar, fbevents, gtm, MicrosoftAdsTracker]),
  consoleTracker,
];

if (getQueryStrings()?.consoleTrack) {
  const consoleTracker: TrackerType = (trackingObj) => console.log('Track event', trackingObj.name);
  trackers = [...trackers, consoleTracker];
}

export const track = (trackingObj) => trackers.map((tr) => tr(trackingObj));

export const trackByName = (name) => track({ name });
