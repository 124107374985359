import React, { useState, useMemo, useCallback } from 'react';
import {
  Center,
  Container,
  Loader,
  Paper,
  Skeleton,
  Title,
  Text,
  Box,
  createStyles,
  Alert,
  SimpleGrid,
} from '@mantine/core';
import Frame from '@unserkunde/enscompare-components/src/components/layout/Frame';
import { ButtonLink } from '@unserkunde/enscompare-components/src/components/components';

import { useIsXs } from '@unserkunde/enscompare-components/src/components/misc/hooks';

import { FaAngleLeft } from 'react-icons/fa';
import type { EnsuranceOffer } from '@/actions/types/EnsuranceOffer';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { evaluateFormular } from '@/actions/customFilter';
import { PageTracking } from '@/actions/tracking/PageTracking';
import { getEnsFilter } from '@/actions/shared/getEnsFilter';
import { useBrowsersBack } from '@/features/BrowserNavigationHandler';
import { AutoloadStandalone } from '@/features/AutoloadEns';
import { dispatchWhenCompareMode, onResetCompareMode } from '../../actions/compare';
import ContactCard from '../shared/ContactCard';
import CompareTable from '../compare/CompareTablePage';
import { sortByPrice, sortByValuation, sortByPopularity } from '../shared/Sort';
import { navigateEnsuranceSelected } from '../../actions/navigation';
import { useEnsOverviewStyles } from '../OverviewControls/EnsuranceOverview';
import { OptionCustom } from '../shared/Option';
import ScrollInfoView from '../shared/ScrollInfoView';
import EnsuranceItem from './EnsuranceItem';
import VoucherInfoRow from './VoucherInfoRow';
import CompareOverlay from './CompareOverlay';

import EnsuranceListOptions, { OptionsGroup } from './EnsuranceListOptions';
import CustomFilterProposal from './CustomFilterProposal';
import { DynamicFilterList } from './DynamicFilterList';
import { EnsuranceProviderFilter } from './EnsuranceProviderFilter';
import { EnsuranceListVoucherInput } from './EnsuranceListVoucherInput';

const sortOptions = [
  {
    key: 'price',
    label: 'Preis',
  },
  {
    key: 'valuation',
    label: 'Leistung',
  },
  {
    key: 'popularity',
    label: 'Beliebtheit',
  },
];

const useStyles = createStyles((theme) => ({
  primaryBox: {
    border: `0px solid transparent !important`,
    '*[class*="-Alert-message"]': {
      overflow: 'visible',
    },
    overflow: 'visible',
  },
  topCardGold: {
    backgroundColor: 'lightgoldenrodyellow',
    background: 'linear-gradient(15deg, rgba(233,233,199,1) 0%, rgba(250,250,210,1) 100%)',
    borderWidth: '0',
    filter: 'drop-shadow(7px 7px 6px #bb9630aa)',
    paddingTop: 1.3 * theme.spacing.xl,
  },
  topCardSilver: {
    backgroundColor: 'rgb(215,15,215)',
    background: 'linear-gradient(-15deg, rgba(215,215,215,1) 0%, rgba(240,240,240,1) 100%)',
    borderWidth: '0',
    filter: 'drop-shadow(7px 7px 6px #b3b3b3dd)',
    paddingTop: 1.3 * theme.spacing.xl,
  },
}));

const FilterArea = () => {
  const hideFilter = useAppSelector((state) => state.ensfields.ens_hide_frontend_filterbox);
  const userData = useAppSelector((state) => state.userData);
  const state = useAppSelector((s) => s);

  const hideFilterResult = evaluateFormular(hideFilter, userData, state, null, false);

  return (
    <Container px='0'>
      <Frame>
        <OptionCustom on={(options) => !options.includes('hideEnsResultFilterBox') && !hideFilterResult}>
          <Box>
            <OptionsGroup>
              <DynamicFilterList />
            </OptionsGroup>

            {/* <InstanceModeSwitch enable={['bike']}>
              <OptionsGroup>
                <Box p='sm'>
                  <PremiumserviceInEnsuranceList />
                </Box>
              </OptionsGroup>
            </InstanceModeSwitch> */}
          </Box>
        </OptionCustom>

        <OptionCustom on={(options) => !options.includes('hideEnsResultFilterBox') && !hideFilterResult}>
          <CustomFilterProposal />
        </OptionCustom>

        <VoucherInfoRow />
      </Frame>
    </Container>
  );
};

const AvailableEnsurance = ({ ensurances }: { ensurances: EnsuranceOffer[] }) => {
  const disableTop = useMemo(() => !ensurances.find((e) => e.orderCountPercent) || !!getEnsFilter(), [ensurances]);

  const kundenwertung = useMemo(() => [...ensurances].filter((e) => !disableTop).sort(sortByPopularity), [ensurances]);

  const bestKundenwertung = useMemo(() => kundenwertung.slice(0, 2), [kundenwertung]);
  const bestNames = useMemo(() => bestKundenwertung.map((ens) => ens.name), [bestKundenwertung]);

  const rest = useMemo(
    /* 
    Vorher wurden die TOPs nicht nochmal mit in der Liste angezeigt. Soll aber doch so sein. 
    https://app.asana.com/0/1174272655772669/1208203053106062/f
    () => ensurances.filter((ens) => bestKundenwertung.length < 2 || !bestNames.includes(ens.name)), 
     */
    () => ensurances,
    [ensurances, bestNames]
  );

  const { classes } = useStyles();

  const isXs = useIsXs();

  return (
    <>
      {bestKundenwertung.length === 2 && (
        <Alert
          variant='outline'
          title='Kundenlieblinge - Die am häufigsten gewählten Angebote'
          className={classes.primaryBox}
          m={0}
          mb='xs'
          p={0}>
          <SimpleGrid
            cols={isXs ? 1 : bestKundenwertung.length}
            w='100%'
            mt='md'
            spacing='md'>
            {bestKundenwertung.map((ensurance: EnsuranceOffer, index: number) => (
              <EnsuranceItem
                item={ensurance}
                key={ensurance.name}
                useSmall
                highlightOrderCountPercent
                eyeBrowInside
                cardClassName={index === 0 ? classes.topCardGold : classes.topCardSilver}
              />
            ))}
          </SimpleGrid>
        </Alert>
      )}

      {rest.map((ensurance: EnsuranceOffer) => (
        <EnsuranceItem
          item={ensurance}
          key={ensurance.name}
        />
      ))}
    </>
  );
};

const EnsuranceListData = () => {
  const allEnsurances = useAppSelector((state) => state.ensuranceList.list) || [];
  const ensuranceList = useMemo(() => allEnsurances.filter((o) => o && !o.failed), [allEnsurances]);

  const loadingProgressInfo = useAppSelector((state) => state.ensuranceList.loadingInfo);
  const isLoading = useAppSelector((state) => state.ensuranceList.loading || state.ensCompare.loading);

  const compareMode = useAppSelector((state) => state.ensCompare.compareMode);

  const [sortBy, setSortBy] = useState('price');

  const [filteredFilterKey, setFilteredFilterKey] = useState<string[] | null>(null);

  const filteredEnsurances = useMemo(() => {
    if (filteredFilterKey === null) return ensuranceList;
    if (filteredFilterKey.length === 0) return ensuranceList;

    return ensuranceList.filter((ens) => filteredFilterKey.includes(ens.filterkey));
  }, [ensuranceList, filteredFilterKey]);

  const failedEnsurances = useMemo(
    () =>
      allEnsurances
        .filter(
          (o) => filteredFilterKey === null || filteredFilterKey.length === 0 || filteredFilterKey.includes(o.filterkey)
        )
        .filter((o) => o && o.failed && o.type !== 'Leistungsausschluss' && o.type !== 'Ausgeblendet'),
    [allEnsurances, filteredFilterKey]
  );

  const sortedEnsuranceList = useMemo(() => {
    switch (sortBy) {
      case 'valuation':
        return [...filteredEnsurances].sort(sortByValuation);
      case 'price':
        return [...filteredEnsurances].sort(sortByPrice);
      case 'popularity':
        return [...filteredEnsurances].sort(sortByPopularity);
      default:
        return [...filteredEnsurances];
    }
  }, [filteredEnsurances, sortBy]);

  if (compareMode) {
    return <CompareTable />;
  }

  return (
    <Container px={0}>
      <ScrollInfoView />
      <FilterArea />

      <EnsuranceListVoucherInput />

      <CompareOverlay />

      <Frame px={0}>
        <EnsuranceListOptions
          sortOptions={sortOptions}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        <EnsuranceProviderFilter onChange={setFilteredFilterKey} />
      </Frame>

      <Frame
        spacing='lg'
        px={0}>
        {ensuranceList.length > 0 || isLoading ? null : <ContactCard />}

        {isLoading && (
          <>
            <Center>
              <Loader />
            </Center>
            <Center>
              {loadingProgressInfo?.total ? (
                <Text>
                  Lade Versicherung {loadingProgressInfo.current || 1} von {loadingProgressInfo.total}{' '}
                </Text>
              ) : (
                <Text>Suche Versicherungen ...</Text>
              )}
            </Center>
          </>
        )}

        {sortedEnsuranceList && sortedEnsuranceList.length > 0 && (
          <AvailableEnsurance ensurances={sortedEnsuranceList} />
        )}

        <>
          {failedEnsurances.length === 0 ? null : <Title order={2}>Ausgeblendente Versicherer</Title>}
          {failedEnsurances.map((ens) => (
            <EnsuranceItem
              item={ens}
              key={ens.name}
              disabled
            />
          ))}
        </>

        {isLoading &&
          loadingProgressInfo.total &&
          Array.from({ length: parseInt(loadingProgressInfo.total, 10) - allEnsurances.length }).map((_, i) => (
            <Paper key={i}>
              {Array.from({ length: 6 }).map((_, i) => (
                <Skeleton
                  key={i}
                  height={8}
                  mt={6}
                  radius='xl'
                />
              ))}
            </Paper>
          ))}
      </Frame>
    </Container>
  );
};

function NavButtonsTop() {
  const dispatch = useAppDispatch();
  const onGoBack = useCallback(
    () => dispatch(dispatchWhenCompareMode(onResetCompareMode(false), navigateEnsuranceSelected.prev())),
    []
  );

  const { classes } = useEnsOverviewStyles();

  useBrowsersBack(onGoBack);

  return (
    <Container className={classes.topButtonRow}>
      <ButtonLink
        leftIcon={<FaAngleLeft />}
        onClick={onGoBack}>
        Zurück
      </ButtonLink>
    </Container>
  );
}

const EnsuranceList = (props) => {
  return (
    <>
      <AutoloadStandalone hide>
        <NavButtonsTop />
      </AutoloadStandalone>
      <EnsuranceListData {...props} />
      <PageTracking name='ensuranceListSelection' />
    </>
  );
};

export default EnsuranceList;
