import React from 'react';
import { Box, Text } from '@mantine/core';

import { useAppSelector, useDynamicFields } from '../../hooks';
import DynamicEntryQuestion, { EntryQuestionContainer } from '../shared/DynamicEntryQuestion';
import OverviewBikeInput from './OverviewBikeInput';
import { EnsAgbControl } from './EnsAgbControl';
import { EntryQuestionButtonLabel } from './EnsuranceOverview';

declare let window: any;

function OverviewInput() {
  const defaultBikeId = useAppSelector((state) => state.userData.defaultBikeId);

  const entryQuestions = useDynamicFields('entryQuestions');
  const entryQuestionsTop = useDynamicFields('entryQuestionsTop');

  const erstinformationenUrl = useAppSelector(
    (state) =>
      state.ensfields.ens_erstinformation_url ||
      'https://ebikeversicherungen.net/wp-content/uploads/2021/03/Erstinformation.pdf'
  );

  const hasSecondBike = useAppSelector((state) => Object.keys(state.userData.bikes).length > 1);

  return (
    <Box>
      <OverviewBikeInput
        bikeId={defaultBikeId}
        bikeIndex={hasSecondBike ? 1 : null}
        fieldsPosition='objectEntryQuestionsTop'
      />

      <EntryQuestionContainer>
        {entryQuestionsTop.map((eo, i) => (
          <DynamicEntryQuestion
            key={i}
            setting={eo}
          />
        ))}
      </EntryQuestionContainer>

      <OverviewBikeInput
        bikeId={defaultBikeId}
        fieldsPosition='objectEntryQuestions'
      />

      <EntryQuestionContainer>
        {entryQuestions.map((eo, i) => (
          <DynamicEntryQuestion
            key={i}
            setting={eo}
          />
        ))}

        <EnsAgbControl buttonLabel={EntryQuestionButtonLabel} />
      </EntryQuestionContainer>
    </Box>
  );
}

export default React.memo(OverviewInput);
