import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';

const Initializer = () => {
  const requiredFieldsLoaded = useAppSelector((state) => state.ensfields.requiredFieldsLoaded);
  const devicemodes = useAppSelector((state) => state.ensfields.devicemodes);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!requiredFieldsLoaded) return;
    dispatch({ type: 'APP_REQUIREMENTS_LOADED', devicemodes });
  }, [requiredFieldsLoaded]);

  return null;
};

export default Initializer;
