import React, { Ref, useEffect, useState } from 'react';

export const InnerSizeObserver = (props: {
  render: (size: DOMRectReadOnly | null, ref) => React.ReactNode;
}) => {
  const { render, ...rest } = props;

  const [ref, setRef] = useState<HTMLElement>(null);
  const [size, setSize] = useState<DOMRectReadOnly | null>(null);

  useEffect(() => {
    if (!ref) return () => {};

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      setSize(entry.contentRect);
    });

    resizeObserver.observe(ref);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);
  return render(size, setRef);
};
